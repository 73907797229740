<template>
  <div class="vg_wrapper vd_top" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="smplForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('smplForm')"
      />
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
      <div class="vd_export1">
        <el-button type="warning" size="small" :disabled="smplForm.status === 0" v-if="isShow && openFlag" @click="generatePrice()"
          ><span v-if="smplForm.quta_id === 0">生成样品报价单</span><span v-else>跳转报价单</span></el-button
        >
        <!-- <el-button
        type="primary"
        size="small"
        class="vd_export1"
        @click="lookEcharts()"
        v-if="isShow && smplForm.status === 2"
        >查看</el-button
      >
      <el-button
        type="primary"
        size="small"
        class="vd_export2"
        @click="smplToMes()"
        v-if="coverFlag && isShow && smplForm.status === 2"
        >{{ smplSendOrCover === 1 ? "发送" : "覆盖" }}</el-button
      >-->
      </div>
    </div>
    <el-form ref="smplForm" :model="smplForm" label-width="120px" size="mini" :disabled="isShow" :rules="rules">
      <el-row>
        <el-col :md="8">
          <el-form-item label="样品图片">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :data="dataBody"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <!-- <img v-if="smplForm.imge_id" :src="smplForm.imge_url" class="avatar"/> -->
              <imgPopover v-if="smplForm.imge_id" :imgeUrl="smplForm.imge_url" :imgeUrlO="smplForm.imge_urlO"></imgPopover>
              <i v-else class="el-icon-plus avatar-uploader-icon" :class="{ vd_cussor: isShow }"></i>
            </el-upload>
            <span>仅支持jpg、png、jpeg格式且大小不能超过10MB</span>
          </el-form-item>
          <div v-if="smplForm.sample_status === 1"><h3 style="color: red; margin: 20px 0 0 50px">打样状态：取消打样</h3></div>
        </el-col>
        <el-col :md="16">
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="样品编号" prop="smpl_no">
                <el-input
                  v-model="smplForm.smpl_no"
                  @input="smplForm.smpl_no = helper.keepEngNum2(smplForm.smpl_no)"
                  maxlength="10"
                  :disabled="smplListDisable"
                  show-word-limit
                  placeholder="请填写样品编号"
                >
                  <!-- <template slot="append">
                    <el-link :disabled="isShow" type="primary" @click="importSmpl">导入</el-link>
                  </template> -->
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="客户货号" prop="smpl_cust_no">
                <el-input
                  v-model="smplForm.smpl_cust_no"
                  @input="smplForm.smpl_cust_no = helper.keepEngNum2(smplForm.smpl_cust_no)"
                  show-word-limit
                  maxlength="30"
                  placeholder="请填写客户货号"
                >
                  <!-- <el-link slot="append" type="primary" @click="notice = true;" :disabled="isShow">导入</el-link> -->
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item
                label="更改号"
                prop="smpl_suffix"
                :rules="!smplListDisable ? [{ required: true, message: ' ' }] : [{ required: false }]"
              >
                <el-input
                  v-model="smplForm.smpl_suffix"
                  maxlength="2"
                  @input="smplForm.smpl_suffix = helper.keepTNum(smplForm.smpl_suffix)"
                  show-word-limit
                  placeholder="请填写更改号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="客户名称" prop="cust_abbr">
                <el-input v-model="smplForm.cust_abbr" maxlength="30" disabled show-word-limit placeholder="请填写客户名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="样品名称" prop="smpl_name">
                <el-input
                  v-model="smplForm.smpl_name"
                  maxlength="30"
                  :disabled="smplListDisable"
                  show-word-limit
                  placeholder="请填写样品名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="样品英文名称" prop="smpl_name_en">
                <el-input
                  v-model="smplForm.smpl_name_en"
                  maxlength="100"
                  disabled
                  show-word-limit
                  placeholder="请填写样品英文名称"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="样品尺寸" prop="smpl_spec">
                <el-input v-model="smplForm.smpl_spec" maxlength="30" show-word-limit placeholder="请填写样品尺寸"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="打样数量" prop="smpl_num" required>
                <el-input
                  v-model="smplForm.smpl_num"
                  maxlength="10"
                  disabled
                  @input="smplForm.smpl_num = helper.keepTNum1(smplForm.smpl_num)"
                  show-word-limit
                  placeholder="请填写打样数量"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="样品类型" prop="smpl_type">
                <el-select v-model="smplForm.smpl_type" :disabled="smplListDisable" placeholder="请选择样品类型" clearable>
                  <el-option v-for="item in smplType" :key="item.id" :label="item.label" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="部件名称" prop="part_name">
                <el-input v-model="smplForm.part_name" maxlength="30" show-word-limit placeholder="请填写部件名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="开版师" prop="smpl_stff_name">
                <el-select filterable v-model="smplForm.smpl_stff_name" placeholder="请选择开版师" clearable>
                  <el-option v-for="item in stffType" :key="item.user_id" :label="item.stff_name" :value="item.stff_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="实际交样日期" prop="smpl_deil_time">
                <el-date-picker v-model="smplForm.smpl_deil_time" type="date" placeholder="选择交样日期"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="皮壳重量" prop="smpl_skin_weight">
                <el-input
                  @change="weightChange"
                  v-model="smplForm.smpl_skin_weight"
                  @input="smplForm.smpl_skin_weight = helper.keepTNum1(smplForm.smpl_skin_weight)"
                  @blur="smplForm.smpl_skin_weight = helper.calcPrice(smplForm.smpl_skin_weight, 4, 1000000)"
                  maxlength="11"
                  show-word-limit
                  placeholder="请填写皮壳重量"
                >
                  <template slot="append">克</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="委托类型" prop="smpl_quot">
                <el-select filterable v-model="smplForm.smpl_quot" placeholder="请选择委托类型">
                  <el-option v-for="item in smplQuotList" :key="item.id" :label="item.label" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item
            label="纸箱长度"
            prop="smpl_box_l"
            :rules="smplForm.freight_flag === '1' ? [{ required: true, message: ' ' }] : [{ required: false }]"
          >
            <el-input
              v-model="smplForm.smpl_box_l"
              @input="smplForm.smpl_box_l = helper.keepTNum1(smplForm.smpl_box_l)"
              @change="numberSizeChange(smplForm.smpl_box_l, 1)"
              maxlength="9"
              show-word-limit
              placeholder="请填写纸箱长度(最小5厘米)"
            >
              <template slot="append">厘米</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="填充物重量" prop="smpl_fill_weight">
            <el-input
              @change="weightChange"
              v-model="smplForm.smpl_fill_weight"
              @input="smplForm.smpl_fill_weight = helper.keepTNum1(smplForm.smpl_fill_weight)"
              @blur="smplForm.smpl_fill_weight = helper.retain1(smplForm.smpl_fill_weight, 2, 1000000)"
              maxlength="9"
              show-word-limit
              placeholder="请填写填充物重量"
            >
              <template slot="append">克</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="打样接收人" prop="requ_receive">
            <el-select disabled filterable v-model="smplForm.requ_receive" placeholder="请选择打样接收人" clearable>
              <el-option v-for="item in stffType" :key="item.user_id" :label="item.stff_name" :value="item.stff_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item
            label="纸箱宽度"
            prop="smpl_box_w"
            :rules="smplForm.freight_flag === '1' ? [{ required: true, message: ' ' }] : [{ required: false }]"
          >
            <el-input
              v-model="smplForm.smpl_box_w"
              @input="smplForm.smpl_box_w = helper.keepTNum1(smplForm.smpl_box_w)"
              @change="numberSizeChange(smplForm.smpl_box_w, 2)"
              maxlength="9"
              show-word-limit
              placeholder="请填写纸箱宽度(最小5厘米)"
            >
              <template slot="append">厘米</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="样品重量" prop="smpl_weight">
            <el-input
              v-model="smplForm.smpl_weight"
              @input="smplForm.smpl_weight = helper.keepTNum1(smplForm.smpl_weight)"
              @blur="smplForm.smpl_weight = helper.calcPrice(smplForm.smpl_weight, 4, 1000000)"
              maxlength="11"
              show-word-limit
              placeholder="请填写样品重量"
            >
              <template slot="append">克</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="委托单号" prop="requ_no">
            <el-input :disabled="smplListDisable" v-model="smplForm.requ_no" show-word-limit maxlength="30" placeholder="请填写委托单号">
              <!-- <el-link slot="append" type="primary" @click="notice = true;" :disabled="isShow">导入</el-link> -->
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item
            label="纸箱高度"
            prop="smpl_box_h"
            :rules="smplForm.freight_flag === '1' ? [{ required: true, message: ' ' }] : [{ required: false }]"
          >
            <el-input
              v-model="smplForm.smpl_box_h"
              @input="smplForm.smpl_box_h = helper.keepTNum1(smplForm.smpl_box_h)"
              @change="numberSizeChange(smplForm.smpl_box_h, 3)"
              maxlength="9"
              show-word-limit
              placeholder="请填写纸箱高度(最小5厘米)"
            >
              <template slot="append">厘米</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="样品间报价" prop="smpl_price">
            <el-input
              v-model="smplForm.smpl_price"
              @input="smplForm.smpl_price = helper.keepTNum1(smplForm.smpl_price)"
              @blur="smplForm.smpl_price = helper.calcPrice(smplForm.smpl_price, 4, 1000000)"
              maxlength="11"
              show-word-limit
              placeholder="请填写样品间报价"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" rows="1" v-model="smplForm.remark" maxlength="200" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item
            label="装箱数"
            prop="smpl_qpc"
            :rules="smplForm.freight_flag === '1' ? [{ required: true, message: ' ' }] : [{ required: false }]"
          >
            <el-input
              v-model="smplForm.smpl_qpc"
              @input="smplForm.smpl_qpc = helper.keepTNum(smplForm.smpl_qpc)"
              maxlength="10"
              show-word-limit
              placeholder="请填写装箱数"
            >
              <template slot="append">箱</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="采购部核价" prop="smpl_verify_price">
            <el-input
              v-model="smplForm.smpl_verify_price"
              @input="smplForm.smpl_verify_price = helper.keepTNum1(smplForm.smpl_verify_price)"
              @blur="smplForm.smpl_verify_price = helper.calcPrice(smplForm.smpl_verify_price, 4, 1000000)"
              maxlength="11"
              show-word-limit
              placeholder="请填写采购部核价"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="版本号" prop="version_number">
            <el-input v-model="smplForm.version_number" disabled placeholder="请填写版本号"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item
            label="塑料袋长度"
            prop="smpl_bag_l"
            :rules="smplForm.freight_flag === '1' ? [{ required: true, message: ' ' }] : [{ required: false }]"
          >
            <el-input
              v-model="smplForm.smpl_bag_l"
              @input="smplForm.smpl_bag_l = helper.keepTNum1(smplForm.smpl_bag_l)"
              @change="numberSizeChange(smplForm.smpl_bag_l, 4)"
              maxlength="9"
              show-word-limit
              placeholder="请填写塑料袋长度(最小5厘米)"
            >
              <template slot="append">厘米</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="运费单价" prop="smpl_freight">
            <el-input
              v-model="smplForm.smpl_freight"
              @input="smplForm.smpl_freight = helper.keepTNum1(smplForm.smpl_freight)"
              @blur="smplForm.smpl_freight = helper.calcPrice(smplForm.smpl_freight, 4, 1000000)"
              maxlength="11"
              show-word-limit
              placeholder="请填写运费单价"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item
            label="塑料袋宽度"
            prop="smpl_bag_w"
            :rules="smplForm.freight_flag === '1' ? [{ required: true, message: ' ' }] : [{ required: false }]"
          >
            <el-input
              v-model="smplForm.smpl_bag_w"
              @input="smplForm.smpl_bag_w = helper.keepTNum1(smplForm.smpl_bag_w)"
              @change="numberSizeChange(smplForm.smpl_bag_w, 5)"
              maxlength="9"
              show-word-limit
              placeholder="请填写塑料袋宽度(最小5厘米)"
            >
              <template slot="append">厘米</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="最小起订量" prop="smpl_moq">
            <el-input
              v-model="smplForm.smpl_moq"
              @input="smplForm.smpl_moq = helper.keepTNum1(smplForm.smpl_moq)"
              maxlength="9"
              show-word-limit
              placeholder="请填写最小起订量"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5"></el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item
            label="装袋数"
            prop="smpl_qpb"
            :rules="smplForm.freight_flag === '1' ? [{ required: true, message: ' ' }] : [{ required: false }]"
          >
            <el-input
              v-model="smplForm.smpl_qpb"
              @input="smplForm.smpl_qpb = helper.keepTNum(smplForm.smpl_qpb)"
              maxlength="9"
              show-word-limit
              placeholder="请填写装袋数"
            >
              <template slot="append">个</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="含运费" prop="freight_flag">
            <el-radio v-model="smplForm.freight_flag" label="1">是</el-radio>
            <el-radio v-model="smplForm.freight_flag" label="0">否</el-radio>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vd_buttom"></el-row>
      <div style="margin: 10px">
        <el-button type="success" plain size="small" @click="importSmpl">导入其他样品数据</el-button>
      </div>
      <el-tabs v-model="activeName" class="vd_eltab vd_buttom" :disabled="true" @tab-click="handleClick">
        <el-tab-pane label="BOM清单" name="first" :key="'first'">
          <div class="vd_but">
            <el-button type="primary" plain size="small" @click="addRow()">导入库存物料</el-button>
            <el-button type="primary" plain size="small" @click="addRowCopy()">导入辅料(无库存)</el-button>
            <el-button type="danger" plain size="small" @click="delBom()">删除</el-button>
            <el-button :disabled="false" type="success" plain size="small" @click="exportExcel()">导出EXCEL</el-button>
          </div>
          <SmplEditBomChild
            :isShow="showBom"
            :mtrlTypeGroupOpt="mtrlTypeGroupOpt"
            :smplForm="smplForm"
            @handleSelectionChange="handleSelectionChange"
            @priceChange="priceChange1"
            v-loading="bomFlag"
          ></SmplEditBomChild>
        </el-tab-pane>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="70%">
          <SmplEditBom
            :smplForm="smplForm"
            @falseBom="falseBom"
            @childBom="childBom"
            @bomChildChanel="bomChildChanel"
            ref="SmplEditBom"
          ></SmplEditBom>
        </el-dialog>
        <el-dialog :title="title2" :visible.sync="dialogVisibleCopy" width="70%">
          <SmplEditBomCopy
            :smplForm="smplForm"
            @childBomCopy="childBom"
            @bomChildChanelCopy="bomChildChanelCopy"
            ref="SmplEditBom"
          ></SmplEditBomCopy>
        </el-dialog>
        <el-tab-pane label="加工面料" name="second" :key="'second'">
          <div class="vd_but">
            <el-button type="primary" plain size="small" @click="addPlmtrl()">添加</el-button>
            <!-- <el-button type="success" plain size="small" @click="importPlmtrl()">导入</el-button> -->
            <el-button type="danger" plain size="small" @click="delPlmtrl()">删除</el-button>
          </div>
          <SmplEditPlmtrlChild
            @compListChange="compListChange"
            v-if="showPlmtrl"
            :isShow="isShow"
            :plmtrlShow="plmtrlShow"
            @plmtrlShowChange="plmtrlShowChange"
            @plmtrlChangeCut="plmtrlChangeCut"
            @priceChange="priceChange2"
            @plmtrlChangeIds="plmtrlChangeIds"
            @deletePlmtrl="deletePlmtrl"
            :mtrlTypeGroupOpt="mtrlTypeGroupOpt"
            :smplForm="smplForm"
            @handleSelectionChange2="handleSelectionChange2"
            v-loading="plmtrlFlag"
          ></SmplEditPlmtrlChild>
        </el-tab-pane>
        <el-dialog :title="title1" :visible.sync="dialogVisible1" width="70%">
          <SmplEditPlmtrl @childPlmtrl="childPlmtrl" @plmtrlChildChanel="plmtrlChildChanel" ref="SmplEditBom"></SmplEditPlmtrl>
        </el-dialog>
        <el-tab-pane label="裁片信息" name="third" :key="'third'">
          <SmplEditCutChild
            @openEdit="openEdit"
            ref="smplCut"
            @cutChangeCuts="cutChangeCuts"
            @refresh="getsmplInfo(0)"
            @cutChangeIds="cutChangeIds"
            :smplForm="smplForm"
            @handleSelectionChange1="handleSelectionChange1"
            v-loading="cutFlag"
            :isShow="isShow"
          ></SmplEditCutChild>
          <div class="vd_but">
            <el-button type="primary" plain size="small" @click="addCut()">添加</el-button>
            <el-button type="primary" plain size="small" @click="copy()">复制</el-button>
            <el-button type="danger" plain size="small" @click="$refs.smplCut.deleteInfo()">删除</el-button>
            <!--            <el-button type="primary" size="small" @click="$refs.smplCut.submit1()">保存</el-button>-->
          </div>
        </el-tab-pane>
        <el-tab-pane label="组合信息" name="sixth" :key="'sixth'">
          <SmplEditCutCheckBoxChild
            ref="smplEditCutCheckBoxChild"
            @confirmSelectData="confirmSelectData"
            :smplForm="smplForm"
            class="vd_but"
          ></SmplEditCutCheckBoxChild>
          <SmplEditCombinationChild
            :editFlag="editFlag"
            ref="smplCom"
            @handleSelectionCombination="handleSelectionCombination"
            :smplForm="smplForm"
            @refresh="getsmplInfo(0)"
            @blurIndex="index => (combinationChildSelectIndex = index)"
            :isShow="isShow"
          ></SmplEditCombinationChild>
          <div class="vd_but" style="padding: 10px">
            <el-button type="primary" plain size="small" @click="addCombination()">添加</el-button>
            <el-button type="danger" plain size="small" @click="delCombination()">删除</el-button>
            <el-button type="primary" size="small" @click="$refs.smplCom.submitCom(1)">保存 </el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="工艺信息" name="fourth" :key="'fourth'">
          <div class="vd_but">
            <el-button type="primary" plain size="small" @click="addSemiprod()">添加</el-button>
            <el-button type="danger" plain size="small" @click="delSemiprod()">删除</el-button>
          </div>
          <SmplEditSemiprodChild
            ref="semiProd"
            @semiprodChangeCut="semiprodChangeCut"
            @semiprodChangeIds="semiprodChangeIds"
            :smplForm="smplForm"
            @handleSelectChangeSemiprod="handleSelectChangeSemiprod"
            v-loading="semiprodFlag"
          ></SmplEditSemiprodChild>
        </el-tab-pane>
        <el-tab-pane label="配件信息" name="fifth" :key="'fifth'">
          <div class="vd_but">
            <el-button type="primary" plain size="small" @click="addPartList()">添加</el-button>
            <el-button type="danger" plain size="small" @click="delPartList()">删除</el-button>
          </div>
          <SmplPartList :smplForm="smplForm" @handleSelectionPart="handleSelectionPart"></SmplPartList>
        </el-tab-pane>
      </el-tabs>
      <el-dialog :title="'图表'" :visible.sync="dialogVisibleEcharts" width="70%">
        <SmplEditEcharts :smplEcharts="smplEcharts" ref="SmplEditEcharts"></SmplEditEcharts>
      </el-dialog>

      <el-tabs v-model="activeName2">
        <el-tab-pane label="开版" name="open" :key="1" v-if="showStatus.open_status != 0">
          <SmplEditOpenChild
            :prop="openProp"
            :rfid_perm_status="rfid_perm_status"
            :rfid_Info="rfid_Info"
            :editFlag="editFlag"
            :originalOpen="originalOpen"
            @copyOpen="copyOpen"
            @openPhrChange="openPhrChange"
            @cutNumChange="cutNumChange"
            @num="addnum"
            @del="del"
          />
        </el-tab-pane>
        <el-tab-pane label="备料" name="stpr" :key="2" v-if="showStatus.stpr_status != 0">
          <SmplEditStprChild
            :prop="stprProp"
            :rfid_perm_status="rfid_perm_status"
            :rfid_Info="rfid_Info"
            :editFlag="editFlag"
            :originalStpr="originalStpr"
            @copyStpr="copyStpr"
            @stprPhrInt="stprPhrInt"
            @num="addnum"
            @del="del"
          />
        </el-tab-pane>
        <el-tab-pane label="复合" name="comp" :key="3" v-if="showStatus.comp_status != 0">
          <SmplEditCompChild
            :rfid_perm_status="rfid_perm_status"
            :prop="compProp"
            :rfid_Info="rfid_Info"
            :editFlag="editFlag"
            :originalComp="originalComp"
            @copyComp="copyComp"
            @compPhrChange="compPhrChange"
            @num="addnum"
            @del="del"
          />
        </el-tab-pane>
        <el-tab-pane label="印花" name="stam" :key="4" v-if="showStatus.stam_status != 0">
          <SmplEditStamChild
            :rfid_perm_status="rfid_perm_status"
            :prop="stamProp"
            :rfid_Info="rfid_Info"
            :editFlag="editFlag"
            :originalStam="originalStam"
            @copyStam="copyStam"
            @stamPhrChange="stamPhrChange"
            @num="addnum"
            @del="del"
          />
        </el-tab-pane>
        <el-tab-pane label="激光" name="lase" :key="5" v-if="showStatus.lase_status != 0">
          <SmplEditLaseChild
            :rfid_perm_status="rfid_perm_status"
            :prop="laseProp"
            :rfid_Info="rfid_Info"
            :editFlag="editFlag"
            :originalLase="originalLase"
            @copyLase="copyLase"
            @lasePhrChange="lasePhrChange"
            @lasePatchChange="lasePatchChange"
            @num="addnum"
            @del="del"
          />
        </el-tab-pane>
        <el-tab-pane label="手裁" name="handCut" :key="6" v-if="showStatus.handCut_status != 0">
          <SmplEditHandChild
            :rfid_perm_status="rfid_perm_status"
            :rfid_Info="rfid_Info"
            :editFlag="editFlag"
            :prop="handCutProp"
            :originalhandCut="originalhandCut"
            @rfid_handcut_phrChange="rfid_handcut_phrChange"
            @sample_phrChange="sample_phrChange"
            @sample_numChange="sample_numChange"
            @copyHand="copyHand"
            @num="addnum"
            @del="del"
          />
        </el-tab-pane>
        <el-tab-pane label="机缝" name="mase" :key="7" v-if="showStatus.mase_status != 0">
          <SmplEditMaseChild
            ref="maseRef"
            :rfid_perm_status="rfid_perm_status"
            :rfid_Info="rfid_Info"
            :editFlag="editFlag"
            :maseProp="maseProp"
            @cutNumChange1="cutNumChange"
            @num="addnum"
            @del="del"
          />
        </el-tab-pane>
        <el-tab-pane label="电绣" name="embr" :key="8" v-if="showStatus.embr_status != 0">
          <SmplEditEmbrChild
            :rfid_perm_status="rfid_perm_status"
            :prop="embrProp"
            :rfid_Info="rfid_Info"
            :editFlag="editFlag"
            :originalEmbr="originalEmbr"
            @copyEmbr="copyEmbr"
            @embrPhrChange="embrPhrChange"
            @num="addnum"
            @del="del"
          />
        </el-tab-pane>
        <el-tab-pane label="制卡" name="emcd" :key="9" v-if="showStatus.emcd_status != 0">
          <SmplEditEmcdChild
            :rfid_perm_status="rfid_perm_status"
            :rfid_Info="rfid_Info"
            :prop="emcdProp"
            :editFlag="editFlag"
            @num="addnum"
            @del="del"
          />
        </el-tab-pane>
        <el-tab-pane label="手工" name="manu" :key="10" v-if="showStatus.manu_status != 0">
          <SmplEditManuChild
            :rfid_perm_status="rfid_perm_status"
            :rfid_Info="rfid_Info"
            :prop="manuProp"
            :editFlag="editFlag"
            @num="addnum"
            @del="del"
          />
        </el-tab-pane>
        <el-tab-pane label="检验" name="insp" :key="11" v-if="showStatus.insp_status != 0">
          <SmplEditInspChild :rfid_perm_status="rfid_perm_status" :prop="inspProp" />
        </el-tab-pane>
      </el-tabs>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="smplForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="样品工单" :visible.sync="smplListVisible" width="70%">
      <SmplListImport :deptId="smplForm.belo_dept_id" :smpl_no="smplForm.smpl_no" @childSmpl="childSmpl" ref="SmplEditIn"></SmplListImport>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { smplAPI } from '@api/modules/smpl';
import { userAPI } from '@api/modules/user';
import { settAPI } from '@/api/modules/sett';
import { qutaAPI } from '@api/modules/quta';
import { optnAPI } from '@api/modules/optn';
import { imgeAPI } from '@api/modules/imge';
import { openAPI } from '@api/modules/open';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import helper from '@assets/js/helper';
import SmplEditBom from '@/views/DevelopManagement/SmplManage/TabChild/Componet/SmplEditBom';
import SmplEditBomCopy from '@/views/DevelopManagement/SmplManage/TabChild/Componet/SmplEditBomCopy';
import SmplEditPlmtrl from '@/views/DevelopManagement/SmplManage/TabChild/Componet/SmplEditPlmtrl';
import SmplEditEcharts from '@/views/DevelopManagement/SmplManage/TabChild/Componet/SmplEditEcharts';
import SmplEditBomChild from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditBomChild';
import SmplEditPlmtrlChild from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditPlmtrlChild';
import SmplEditCutChild from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditCutChild';
import SmplEditSemiprodChild from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditSemiprodChild';
import SmplEditCombinationChild from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditCombinationChild';
import SmplEditCutCheckBoxChild from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditCutCheckBoxChild';
import SmplEditOpenChild from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditOpenChild';
import SmplEditStprChild from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditStprChild';
import SmplEditCompChild from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditCompChild';
import SmplEditStamChild from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditStamChild';
import SmplEditLaseChild from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditLaseChild';
import SmplEditHandChild from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditHandChild';
import SmplEditMaseChild from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditMaseChild';
import SmplEditEmbrChild from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditEmbrChild';
import SmplEditEmcdChild from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditEmcdChild';
import SmplEditManuChild from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditManuChild';
import SmplEditInspChild from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplEditInspChild';
import SmplPartList from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/SmplPartList';
import SmplListImport from './Componet/SmplListImport.vue';
import imgPopover from '@/views/component/imgPopover';
import { apprAPI } from '@api/modules/appr';
import { cloneDeep } from 'lodash';

export default {
  name: 'smplEditMain',
  components: {
    editHeader,
    inputUser,
    SmplEditBom,
    SmplEditBomCopy,
    SmplEditBomChild,
    SmplEditPlmtrlChild,
    SmplEditCutChild,
    SmplEditPlmtrl,
    SmplEditEcharts,
    SmplEditSemiprodChild,
    SmplEditCombinationChild,
    SmplEditCutCheckBoxChild,
    SmplEditOpenChild,
    SmplEditStprChild,
    SmplEditCompChild,
    SmplEditStamChild,
    SmplEditLaseChild,
    SmplEditHandChild,
    SmplEditMaseChild,
    SmplEditEmbrChild,
    SmplEditEmcdChild,
    SmplEditManuChild,
    imgPopover,
    SmplEditInspChild,
    SmplListImport,
    SmplPartList
  },
  data() {
    return {
      combinationChildSelectIndex: '',
      smplForm: {
        freight_flag: '0',
        smpl_quot: 1,
        belo_dept_id: '',
        requ_receive: ''
      },
      rules: {
        smpl_no: [{ required: true, trigger: 'blur', message: ' ' }],
        smpl_name: [{ required: true, trigger: 'blur', message: ' ' }],
        smpl_type: [{ required: true, trigger: 'change', message: ' ' }]
      },
      num: 0,
      smpl_id: '',
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleCopy: false,
      activeName: 'first',
      activeName2: '',
      isShow: true,
      btn: {},
      mtrlTypeGroupOpt: [],
      selectionsList: [],
      plmtrlSelect: [],
      cutSelect: [],
      semiprodSelect: [],
      bomCopy: [],
      plmtrlCopy: [],
      cutCopy: [],
      semiprodCopy: [],
      title: '库存物料信息',
      title1: '加工面料',
      title2: '辅料信息',
      smplType: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' }
      ],
      smplQuotList: [
        { id: 1, label: '打样并报价' },
        { id: 2, label: '试样报价' },
        { id: 3, label: '仅打样' },
        { id: 4, label: '估价' },
        { id: 5, label: '返工' },
        { id: 8, label: '找料' },
        { id: 9, label: '印刷' },
        { id: 10, label: '电绣' }
      ],
      stffType: [],
      bomSmplMtrlId: -1,
      cutSmplMtrlId: -1001,
      plmtrlSmplMtrlId: -501,
      semiprodSmplMtrlId: -1501,
      bomFlag: false,
      plmtrlFlag: false,
      cutFlag: false,
      semiprodFlag: false,
      plmtrlOption: [],
      cutOption: [],
      semiprodOption: [],
      stffForm: {
        stff_name: '',
        cptt_name: '',
        dept_name: '',
        dept_team_name: '',
        stff_id: Number,
        cptt_id: Number,
        dept_id: Number,
        dept_team_id: Number
      },
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      },
      showPlmtrl: true,
      showSemiprod: true,
      showBom: true,
      plmtrlShow: false,
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      show: false,
      imgForm: {
        imge_id: null,
        imge_url: null,
        imge_urlO: null
      },
      openProp: {
        rfid_open_phr: '',
        rfid_cut_num: ''
      },
      laseProp: {
        rfid_lase_phr: '',
        rfid_patch_lase: ''
      },
      handCutProp: {
        rfid_handcut_phr: '',
        sample_phr: '',
        sample_num: ''
      },
      maseProp: {},
      manuProp: {},
      emcdProp: {
        rfid_emcd_phr: '',
        rfid_patch_num: '',
        rfid_emcd_num: '',
        rfid_emcd_numb: ''
      },
      embrProp: {
        rfid_embr_phr: ''
      },
      stamProp: {
        rfid_stam_phr: ''
      },
      stprProp: {
        rfid_stpr_phr: ''
      },
      inspProp: {},
      compProp: {
        rfid_comp_phr: ''
      },
      loadingFlag: true,
      showStatus: {},
      rfid_perm_status: null,
      smplSendOrCover: null,
      rfid_Info: {
        rfid_id: null,
        user_id: null,
        stff_id: null,
        stff_name: ''
      },
      editFlag: false,
      originalOpen: {
        rfid_open_phr: '',
        rfid_cut_num: ''
      },
      originalStpr: {
        rfid_stpr_phr: ''
      },
      originalComp: {
        rfid_comp_phr: ''
      },
      originalStam: {
        rfid_stam_phr: ''
      },
      originalLase: {
        rfid_lase_phr: '',
        rfid_patch_lase: ''
      },
      originalhandCut: {
        rfid_handcut_phr: '',
        sample_phr: '',
        sample_num: ''
      },
      originalEmbr: {
        rfid_embr_phr: ''
      },
      coverFlag: false,
      dialogVisibleEcharts: false,
      smplEcharts: {},
      settNum: '',
      openFlag: true,
      smplListVisible: false,
      smplListDisable: true,
      partSelectChoose: [],
      partCopy: [],
      combinationSelect: []
    };
  },
  mounted() {
    this.initData();
    if (this.$route.query.opendiFlag === '1') {
      this.openFlag = false;
    }
  },
  watch: {},
  methods: {
    handleClick() {
      if (this.activeName === 'third') {
        this.$refs.smplCut.groupOptions();
      } else if (this.activeName === 'fourth') {
        this.$refs.semiProd.getSemiprodOptions();
      }
      // this.mesSave();//保存裁片和组合
      if (!this.showBom) {
        this.saveInfo(1); //保存
      }
    },
    initData() {
      this.bomSmplMtrlId = -1;
      this.cutSmplMtrlId = -1001;
      this.plmtrlSmplMtrlId = -501;
      this.semiprodSmplMtrlId = -1501;
      this.plmtrlOption = [];
      this.cutOption = [];
      this.semiprodOption = [];
      this.loadingFlag = true;
      this.getsmplInfo(0);
      this.getStffType();
      this.getMtrlType();
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.showBom = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      if (this.num > 0) {
        return this.$message.warning('请先保存RFId卡信息');
      }
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.showBom = true;
          this.$emit('isShow', this.isShow);
          this.show = true;
          this.bomSmplMtrlId = -1;
          this.cutSmplMtrlId = -1001;
          this.plmtrlSmplMtrlId = -501;
          this.semiprodSmplMtrlId = -1501;
          this.plmtrlOption = [];
          this.cutOption = [];
          this.semiprodOption = [];
          this.smplListDisable = true;
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
          this.initData();
        })
        .catch(() => {});
    },
    // 获取发送还是覆盖
    getMesProdBySmplNo() {
      get(openAPI.getMesProdBySmplNo, { smpl_no: this.smplForm.smpl_no })
        .then(res => {
          if (res.data.code === 0) {
            this.smplSendOrCover = res.data.code;
            let temp = {};
            temp.show = true;
            temp.smpl_id = this.smplForm.smpl_id;
            this.$emit('showButton', temp);
          } else if (res.data.code === 1) {
            let temp = {};
            temp.show = false;
            temp.smpl_id = this.smplForm.smpl_id;
            this.smplSendOrCover = res.data.code;
            this.$emit('showButton', temp);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 同步mes
    smplToMes() {
      this.$confirm(this.smplSendOrCover === 1 ? '是否发送' : '是否覆盖', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          post(openAPI.smplToMes, { smpl_id: this.smplForm.smpl_id })
            .then(res => {
              if (res.data.code === 0) {
                this.getMesProdBySmplNo();
                this.$message({
                  type: 'success',
                  message: this.smplSendOrCover === 1 ? '发送成功' : '覆盖成功'
                });
              } else {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({ message: mg, type: tp });
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            });
        })
        .catch(() => {});
    },
    //生成采购报价单
    generatePrice() {
      if (this.smplForm.quta_id === 0) {
        if (!this.smplForm.imge_id) {
          return this.$message.warning('请上传图片');
        }
        let params = {};
        let userInfo = this.$cookies.get('userInfo');
        params.smpl_id = this.smplForm.smpl_id;
        params.user_id = userInfo.user_id;
        params.stff_id = userInfo.stff_id;
        params.dept_id = userInfo.dept_id;
        params.dept_team_id = userInfo.dept_team_id;
        params.stff_name = userInfo.stff_name;
        post(qutaAPI.addQuta, params)
          .then(res => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '生成成功'
              });
              this.initData();
              this.jump('/quta_edit', {
                perm_id: 123,
                form_id: res.data.data.form_id
              });
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            }
          })
          .catch(() => {});
      } else {
        this.jump('/quta_edit', {
          perm_id: 123,
          form_id: this.smplForm.quta_id
        });
      }
    },
    //提交表单
    submit(formName) {
      if (this.num > 0) {
        return this.$message.warning('请先保存RFId卡工序信息');
      }
      let constr;
      if (this.$route.query.rework === 'true') {
        constr = '是否确认保存？保存之后单据将重新变为草拟状态';
      } else {
        constr = '确认提交?';
      }
      this.$confirm(constr, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(item => this.$message.error('保存失败！'));
    },
    // 保存
    saveInfo(val) {
      //字符转换
      for (let i = 0; i < this.smplForm.smpl_combination_List.length; i++) {
        if (this.smplForm.smpl_combination_List[i].craftActionIds) {
          this.smplForm.smpl_combination_List[i].craftActionIds = this.smplForm.smpl_combination_List[i].craftActionIds.join(',');
        }
      }
      // 打补丁
      this.smplForm.smpl_semiprod_list = this.$refs.semiProd.$data.smpl_semiprod_list;
      // --------
      let smplForm = {};
      smplForm = Object.assign(smplForm, this.smplForm);
      smplForm.smpl_deil_time = parseInt(smplForm.smpl_deil_time / 1000);
      this.bomCopy = this.bomCopy.filter(item => smplForm.smpl_mtrl_list.every(item1 => item.smpl_mtrl_id !== item1.smpl_mtrl_id));
      this.plmtrlCopy = this.plmtrlCopy.filter(item => smplForm.smpl_plmtrl_list.every(item1 => item.smpl_mtrl_id !== item1.smpl_mtrl_id));
      // this.cutCopy = this.cutCopy.filter(item => smplForm.smpl_cut_list.every(item1 => item.smpl_mtrl_id !== item1.smpl_mtrl_id));
      this.semiprodCopy = this.semiprodCopy.filter(item =>
        this.smplForm.smpl_semiprod_list.every(item1 => item.smpl_mtrl_id !== item1.smpl_mtrl_id)
      );
      this.partCopy = this.partCopy.filter(item => smplForm.smpl_part_list.every(item1 => item.smpl_part_id !== item1.smpl_part_id));
      for (let i = 0; i < this.bomCopy.length; i++) {
        this.bomCopy[i].destroy_flag = 1;
      }
      for (let i = 0; i < this.plmtrlCopy.length; i++) {
        this.plmtrlCopy[i].destroy_flag = 1;
      }
      for (let i = 0; i < this.cutCopy.length; i++) {
        this.cutCopy[i].destroy_flag = 1;
      }
      for (let i = 0; i < this.semiprodCopy.length; i++) {
        this.semiprodCopy[i].destroy_flag = 1;
      }
      for (let i = 0; i < this.partCopy.length; i++) {
        this.partCopy[i].destroy_flag = 1;
      }
      smplForm.smpl_mtrl_list = smplForm.smpl_mtrl_list.concat(this.bomCopy);
      smplForm.smpl_plmtrl_list = smplForm.smpl_plmtrl_list.concat(this.plmtrlCopy);
      // smplForm.smpl_cut_list = smplForm.smpl_cut_list.concat(this.cutCopy);
      smplForm.smpl_part_list = smplForm.smpl_part_list.concat(this.partCopy);
      smplForm.smpl_semiprod_list = smplForm.smpl_semiprod_list.concat(this.semiprodCopy);
      // for (let i = 0; i < smplForm.smpl_cut_list.length; i++) {
      //   smplForm.smpl_cut_list[i].mtrl_no = smplForm.smpl_no + '@' + smplForm.smpl_cut_list[i].mtrl_no;
      // }
      for (let i = 0; i < smplForm.smpl_semiprod_list.length; i++) {
        smplForm.smpl_semiprod_list[i].mtrl_no = smplForm.smpl_no + '#' + smplForm.smpl_semiprod_list[i].mtrl_no;
      }
      const { rework, perm_id, form_id } = this.$route.query;
      smplForm.rework = rework;
      // 是否重做
      if (rework === 'true') {
        // 反生效
        post(apprAPI.makeIneffective, {
          perm_id: perm_id,
          form_id: form_id
        }).then(({ data }) => {});
      }
      post(smplAPI.editSmpl, smplForm)
        .then(res => {
          if (res.data.code === 0) {
            this.mesSave(); //保存裁片和组合
            //切换保存
            if (val === 1) {
              setTimeout(() => {
                this.initData();
              }, 100);
              return;
            }
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.showBom = true;
            this.bomSmplMtrlId = -1;
            this.cutSmplMtrlId = -1001;
            this.plmtrlSmplMtrlId = -501;
            this.semiprodSmplMtrlId = -1501;
            this.plmtrlOption = [];
            this.cutOption = [];
            this.semiprodOption = [];
            this.smplListDisable = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: '单据编号重复'
            });
            smplForm.smpl_deadline = parseInt(smplForm.smpl_deadline * 1000);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            smplForm.smpl_deadline = parseInt(smplForm.smpl_deadline * 1000);
            // this.initData();
          }
        })
        .catch(err => {
          this.$message.error(err);
        });
    },
    // 刷新按钮
    buttonRefresh() {
      this.bomSmplMtrlId = -1;
      this.cutSmplMtrlId = -1001;
      this.plmtrlSmplMtrlId = -501;
      this.semiprodSmplMtrlId = -1501;
      this.plmtrlOption = [];
      this.cutOption = [];
      this.semiprodOption = [];
      this.initData();
    },
    // 导入smpllist传值
    childSmpl(val, val1) {
      this.$confirm('此操作将覆盖此样品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.confirmSmplList(val, val1);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消覆盖!'
          });
          this.smplListVisible = false;
        });
      // this.$refs.SmplEditIn.clear()
      // this.smplListVisible = false;
      // this.bomSmplMtrlId = -1;
      // this.cutSmplMtrlId = -1001;
      // this.plmtrlSmplMtrlId = -501;
      // this.semiprodSmplMtrlId = -1501;
      // this.plmtrlOption = [];
      // this.cutOption = [];
      // this.semiprodOption = [];
      // this.smplListDisable = false;
      // this.getsmplInfo(1,val)
    },
    confirmSmplList(val, val1) {
      let params = {};
      params.smpl_id = this.smplForm.smpl_id;
      params.temp_smpl_id = val;
      params = Object.assign(params, val1);
      post(smplAPI.copyNewSmpl, params)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '覆盖成功!'
            });
            // this.showBom = true;
            this.bomSmplMtrlId = -1;
            this.cutSmplMtrlId = -1001;
            this.plmtrlSmplMtrlId = -501;
            this.semiprodSmplMtrlId = -1501;
            this.plmtrlOption = [];
            this.cutOption = [];
            this.semiprodOption = [];
            this.smplListDisable = true;
            this.smplListVisible = false;
            this.initData();
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: '单据编号重复'
            });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 打开导入样品弹框
    importSmpl() {
      this.smplListVisible = true;
    },
    // 查看图标
    lookEcharts() {
      this.dialogVisibleEcharts = true;
      let smplForm = JSON.parse(JSON.stringify(this.smplForm));
      let smplEchart = {};
      smplEchart.name = '样品编号:' + smplForm.smpl_no;
      smplEchart.children = [];
      if (smplForm.smpl_mtrl_list.length > 0) {
        let temp = {};
        temp.name = 'BOM清单';
        temp.children = [];
        for (let i = 0; i < smplForm.smpl_mtrl_list.length; i++) {
          let numArticles = {};
          let arr = [];
          numArticles.name = 'BOM物料' + i;
          numArticles.children = [];
          arr.push('物料编号:' + smplForm.smpl_mtrl_list[i].mtrl_no);
          arr.push('物料名称:' + smplForm.smpl_mtrl_list[i].mtrl_name);
          if (smplForm.smpl_mtrl_list[i].mtrl_type === 0) {
            arr.push('物料类型:原面料');
          } else if (smplForm.smpl_mtrl_list[i].mtrl_type === 1) {
            arr.push('物料类型:辅料');
          } else if (smplForm.smpl_mtrl_list[i].mtrl_type === 2) {
            arr.push('物料类型:包材');
          }
          for (let j = 0; j < arr.length; j++) {
            let obj = {};
            obj.name = arr[j];
            numArticles.children.push(obj);
          }
          temp.children.push(numArticles);
        }
        smplEchart.children.push(temp);
      }
      if (smplForm.smpl_plmtrl_list.length > 0) {
        let temp = {};
        temp.name = '加工面料';
        temp.children = [];
        for (let i = 0; i < smplForm.smpl_plmtrl_list.length; i++) {
          let numArticles = {};
          let arr = [];
          numArticles.name = '加工面料' + i;
          numArticles.children = [];
          arr.push('物料编号:' + smplForm.smpl_plmtrl_list[i].mtrl_no);
          arr.push('物料名称:' + smplForm.smpl_plmtrl_list[i].mtrl_name);
          arr.push({ names: '所用物料', label: smplForm.smpl_plmtrl_list[i].smpl_parent_listO });
          for (let j = 0; j < arr.length; j++) {
            let obj = {};
            if (typeof arr[j] === 'object') {
              obj.name = arr[j].names;
              obj.children = [];
              for (let a = 0; a < arr[j].label.length; a++) {
                let mtrlNo = {};
                mtrlNo.name = '物料编号:' + arr[j].label[a].mtrl_no;
                obj.children.push(mtrlNo);
              }
              numArticles.children.push(obj);
            } else {
              obj.name = arr[j];
              numArticles.children.push(obj);
            }
          }
          temp.children.push(numArticles);
        }
        smplEchart.children.push(temp);
      }
      if (smplForm.smpl_cut_list.length > 0) {
        let temp = {};
        temp.name = '裁片信息';
        temp.children = [];
        for (let i = 0; i < smplForm.smpl_cut_list.length; i++) {
          let numArticles = {};
          let arr = [];
          numArticles.name = '裁片信息' + i;
          numArticles.children = [];
          arr.push('物料编号:' + smplForm.smpl_cut_list[i].mtrl_no);
          arr.push('物料名称:@' + smplForm.smpl_no + smplForm.smpl_cut_list[i].mtrl_name);
          smplForm.smpl_cut_list[i].cutOption.forEach(item => {
            if (item.smpl_mtrl_id === smplForm.smpl_cut_list[i].smpl_parent_value) {
              arr.push('所用物料:' + item.mtrl_no);
            }
          });
          for (let j = 0; j < arr.length; j++) {
            let obj = {};
            obj.name = arr[j];
            numArticles.children.push(obj);
          }
          temp.children.push(numArticles);
        }
        smplEchart.children.push(temp);
      }
      if (smplForm.smpl_semiprod_list.length > 0) {
        let temp = {};
        temp.name = '在制品信息';
        temp.children = [];
        for (let i = 0; i < smplForm.smpl_semiprod_list.length; i++) {
          let numArticles = {};
          let arr = [];
          numArticles.name = '在制品信息' + i;
          numArticles.children = [];
          arr.push('物料编号:#' + smplForm.smpl_no + smplForm.smpl_semiprod_list[i].mtrl_no);
          arr.push('物料名称:' + smplForm.smpl_semiprod_list[i].mtrl_name);
          let usedSEmi = {};
          usedSEmi.name = '所用物料';
          usedSEmi.children = [];
          for (let a of smplForm.smpl_semiprod_list[i].smpl_parent_list) {
            for (let b of smplForm.smpl_semiprod_list[i].semiprodOption) {
              if (a === b.smpl_mtrl_id) {
                usedSEmi.children.push({ name: '物料编号:' + b.mtrl_no });
              }
            }
          }
          arr.push(usedSEmi);
          for (let j = 0; j < arr.length; j++) {
            let obj = {};
            if (typeof arr[j] === 'object') {
              numArticles.children.push(arr[j]);
            } else {
              obj.name = arr[j];
              numArticles.children.push(obj);
            }
          }
          temp.children.push(numArticles);
        }
        smplEchart.children.push(temp);
      }
      this.smplEcharts = smplEchart;
    },
    // 计算样品重量
    weightChange() {
      //  this.smplForm.smpl_weight = this.helper.haveFour(Number(this.smplForm.smpl_skin_weight) + Number(this.smplForm.smpl_fill_weight));
    },
    // 数字不能小于10
    numberSizeChange(val, val1) {
      if (Number(val) < 5) {
        this.$message.warning('不可小于5厘米');
        if (val1 === 1) {
          this.smplForm.smpl_box_l = null;
        } else if (val1 === 2) {
          this.smplForm.smpl_box_w = null;
        } else if (val1 === 3) {
          this.smplForm.smpl_box_h = null;
        } else if (val1 === 4) {
          this.smplForm.smpl_bag_l = null;
        } else if (val1 === 5) {
          this.smplForm.smpl_bag_w = null;
        }
      } else {
        if (val1 === 1) {
          this.smplForm.smpl_box_l = this.helper.reservedDigits(this.smplForm.smpl_box_l);
        } else if (val1 === 2) {
          this.smplForm.smpl_box_w = this.helper.reservedDigits(this.smplForm.smpl_box_w);
        } else if (val1 === 3) {
          this.smplForm.smpl_box_h = this.helper.reservedDigits(this.smplForm.smpl_box_h);
        } else if (val1 === 4) {
          this.smplForm.smpl_bag_l = this.helper.reservedDigits(this.smplForm.smpl_bag_l);
        } else if (val1 === 5) {
          this.smplForm.smpl_bag_w = this.helper.reservedDigits(this.smplForm.smpl_bag_w);
        }
      }
    },
    // 添加图片
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.smplForm.imge_id = res.data.imge_id;
        this.smplForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
        this.smplForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
      } else {
        let mg = res.msg;
        let tp = 'error';
        this.$message({ message: mg, type: tp });
      }
    },
    // 上传头像前
    beforeAvatarUpload() {},
    // 裁片选择框选中
    handleSelectChangeSemiprod(val) {
      this.semiprodSelect = [];
      this.semiprodSelect = val;
    },
    // 配件新增
    addPartList() {
      let item = {
        key: this.smplForm.smpl_part_list.length,
        smpl_part_name: null,
        smpl_part_spec: null,
        smpl_part_price: null,
        smpl_part_weight: null
      };
      this.smplForm.smpl_part_list.push(item);
    },
    // 配件删除
    delPartList() {
      if (this.partSelectChoose.length === 0) return this.$message.warning('至少选择一条数据');
      let temp = [];
      this.partSelectChoose.forEach(item => temp.push(item.key));
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let j of temp) {
        this.smplForm.smpl_part_list.splice(j, 1);
      }
      this.smplForm.smpl_part_list.forEach((item, index) => (item.key = index));
    },
    // 配件信息勾选框
    handleSelectionPart(val) {
      this.partSelectChoose = JSON.parse(JSON.stringify(val));
    },
    // 在制品新增
    addSemiprod() {
      // 打补丁
      this.smplForm.smpl_semiprod_list = cloneDeep(this.$refs.semiProd.$data.smpl_semiprod_list);
      // ----------------------------------------------------------------
      if (
        (this.smplForm.smpl_semiprod_list.length > 0 &&
          this.smplForm.smpl_semiprod_list[this.smplForm.smpl_semiprod_list.length - 1].mtrl_no) ||
        this.smplForm.smpl_semiprod_list.length === 0
      ) {
        this.semiprodFlag = true;
        setTimeout(() => {
          let item = {
            // mtrl_no: this.smplForm.smpl_semiprod_list.length + 1,
            mtrl_no:
              this.smplForm.smpl_semiprod_list.length === 0
                ? 1
                : Math.max(...this.smplForm.smpl_semiprod_list.map(({ mtrl_no }) => Number(mtrl_no))) + 1,
            mtrl_name: null,
            smpl_parent_ids: null,
            smpl_mtrl_id: this.semiprodSmplMtrlId,
            smpl_parent_list: [],
            semiprodOption: JSON.parse(JSON.stringify(this.semiprodOption))
          };
          this.smplForm.smpl_semiprod_list.push(item);
          this.$refs.semiProd.$data.smpl_semiprod_list.push(item);
          this.semiprodSmplMtrlId--;
          this.semiprodFlag = false;
        }, 500);
      } else {
        this.$message.warning('请把物料编号补充完整再进行添加');
      }
    },
    // 在制品删除
    delSemiprod() {
      // 打补丁
      this.smplForm.smpl_semiprod_list = cloneDeep(this.$refs.semiProd.$data.smpl_semiprod_list);
      // ----------
      if (this.semiprodSelect.length > 0) {
        this.semiprodFlag = true;
        this.bomFlag = true;
        this.plmtrlFlag = true;
        this.cutFlag = true;
        let temp = [];
        for (let i = 0; i < this.semiprodSelect.length; i++) {
          let ind = null;
          ind = this.smplForm.smpl_semiprod_list.findIndex(x => x.smpl_mtrl_id === this.semiprodSelect[i].smpl_mtrl_id);
          temp.push(ind);
          for (let j = 0; j < this.smplForm.smpl_semiprod_list.length; j++) {
            for (let a = 0; a < this.smplForm.smpl_semiprod_list[j].smpl_parent_list.length; a++) {
              if (Number(this.semiprodSelect[i].smpl_mtrl_id) === this.smplForm.smpl_semiprod_list[j].smpl_parent_list[a]) {
                this.smplForm.smpl_semiprod_list[j].smpl_parent_list.splice(a, 1);
                break;
              }
            }
            for (let a = 0; a < this.smplForm.smpl_semiprod_list[j].semiprodOption.length; a++) {
              if (Number(this.semiprodSelect[i].smpl_mtrl_id) === this.smplForm.smpl_semiprod_list[j].semiprodOption[a].smpl_mtrl_id) {
                this.smplForm.smpl_semiprod_list[j].semiprodOption.splice(a, 1);
                break;
              }
            }
          }
          for (let j in this.semiprodOption) {
            if (Number(this.semiprodSelect[i].smpl_mtrl_id) === this.semiprodOption[j].smpl_mtrl_id) {
              this.semiprodOption.splice(j, 1);
              break;
            }
          }
        }
        for (let i = 0; i < this.smplForm.smpl_semiprod_list.length; i++) {
          if (this.smplForm.smpl_semiprod_list[i].smpl_parent_list.length === 0) {
            this.smplForm.smpl_semiprod_list[i].smpl_parent_ids = '';
          } else if (this.smplForm.smpl_semiprod_list[i].smpl_parent_list.length === 1) {
            this.smplForm.smpl_semiprod_list[i].smpl_parent_ids = this.smplForm.smpl_semiprod_list[i].smpl_parent_list[0].toString();
          } else {
            this.smplForm.smpl_semiprod_list[i].smpl_parent_ids = this.smplForm.smpl_semiprod_list[i].smpl_parent_list.join(',');
          }
        }
        temp = temp.sort((a, b) => {
          return b - a;
        });
        for (let j of temp) {
          this.smplForm.smpl_semiprod_list.splice(j, 1);
        }
        this.$refs.semiProd.$data.smpl_semiprod_list = cloneDeep(this.smplForm.smpl_semiprod_list);
        setTimeout(() => {
          this.bomFlag = false;
          this.plmtrlFlag = false;
          this.cutFlag = false;
          this.semiprodFlag = false;
        }, 500);
      } else {
        this.$message({
          message: '至少选择一条信息',
          type: 'warning'
        });
      }
    },
    // 在制品回显使用物料
    semiprodChangeIds({ val, index }) {},
    //在制品填写编号时修改在制品的一些选项值
    semiprodChangeCut(scope) {
      if (scope.row.mtrl_no.indexOf(' ') === -1) {
        let semiprodTemp = -1;
        let temp = {};
        temp.smpl_mtrl_id = scope.row.smpl_mtrl_id;
        temp.mtrl_no = this.smplForm.smpl_no + '#' + scope.row.mtrl_no;
        for (let i = 0; i < this.semiprodOption.length; i++) {
          if (this.semiprodOption[i].smpl_mtrl_id === scope.row.smpl_mtrl_id) {
            semiprodTemp = i;
          }
        }
        if (semiprodTemp !== -1) {
          this.semiprodOption[semiprodTemp].mtrl_no = scope.row.mtrl_no;
        } else {
          this.semiprodOption.push(temp);
        }
        for (let i = 0; i < this.smplForm.smpl_semiprod_list.length; i++) {
          for (let j = 0; j < this.smplForm.smpl_semiprod_list[i].semiprodOption.length; j++) {
            if (this.smplForm.smpl_semiprod_list[i].semiprodOption[j].smpl_mtrl_id === scope.row.smpl_mtrl_id) {
              this.smplForm.smpl_semiprod_list[i].semiprodOption[j].mtrl_no = this.smplForm.smpl_no + '#' + scope.row.mtrl_no;
            }
          }
        }
      }
    },
    // 裁片选择框选中
    handleSelectionChange1(val) {
      this.cutSelect = [];
      this.cutSelect = val;
    },
    //在制品填写编号时修改在制品的一些选项值
    cutChangeCuts(scope) {
      if (scope.row.mtrl_no.indexOf(' ') === -1) {
        let semiprodTemp = -1;
        let temp = {};
        temp.smpl_mtrl_id = scope.row.smpl_mtrl_id;
        temp.mtrl_no = this.smplForm.smpl_no + '@' + scope.row.mtrl_no;
        for (let i = 0; i < this.semiprodOption.length; i++) {
          if (this.semiprodOption[i].smpl_mtrl_id === scope.row.smpl_mtrl_id) {
            semiprodTemp = i;
          }
        }
        if (semiprodTemp !== -1) {
          this.semiprodOption[semiprodTemp].mtrl_no = scope.row.mtrl_no;
        } else {
          this.semiprodOption.push(temp);
        }
        for (let i = 0; i < this.smplForm.smpl_semiprod_list.length; i++) {
          this.smplForm.smpl_semiprod_list[i].semiprodOption = this.semiprodOption;
          for (let j = 0; j < this.smplForm.smpl_semiprod_list[i].semiprodOption.length; j++) {
            if (this.smplForm.smpl_semiprod_list[i].semiprodOption[j].smpl_mtrl_id === scope.row.smpl_mtrl_id) {
              this.smplForm.smpl_semiprod_list[i].semiprodOption[j].mtrl_no = this.smplForm.smpl_no + '@' + scope.row.mtrl_no;
            }
          }
        }
      }
    },
    //在裁片名称填写时提示重复
    cutChangeCuts1(scope) {
      if (scope.row.mtrl_name.indexOf(' ') === -1) {
        let semiprodTemp = -1;
        let temp = {};
        temp.smpl_mtrl_id = scope.row.smpl_mtrl_id;
        temp.mtrl_name = scope.row.mtrl_name;
        for (let i = 0; i < this.semiprodOption.length; i++) {
          if (this.semiprodOption[i].smpl_mtrl_id === scope.row.smpl_mtrl_id) {
            semiprodTemp = i;
          }
        }
        if (semiprodTemp !== -1) {
          this.semiprodOption[semiprodTemp].mtrl_name = scope.row.mtrl_name;
        } else {
          this.semiprodOption.push(temp);
        }
        for (let i = 0; i < this.smplForm.smpl_semiprod_list.length; i++) {
          this.smplForm.smpl_semiprod_list[i].semiprodOption = this.semiprodOption;
          for (let j = 0; j < this.smplForm.smpl_semiprod_list[i].semiprodOption.length; j++) {
            if (this.smplForm.smpl_semiprod_list[i].semiprodOption[j].smpl_mtrl_id === scope.row.smpl_mtrl_id) {
              this.smplForm.smpl_semiprod_list[i].semiprodOption[j].mtrl_name = scope.row.mtrl_no;
            }
          }
        }
      }
    },
    copy() {
      let cloneData = cloneDeep(this.cutSelect);
      cloneData.forEach((item, index) => {
        item.smpl_mtrl_id = null;
        item.mtrl_name = '';
        item.mtrl_no =
          this.smplForm.smpl_cut_list.length === 0
            ? 1
            : Math.max(...this.smplForm.smpl_cut_list.map(({ mtrl_no }) => Number(mtrl_no))) + index + 1;
      });

      this.smplForm.smpl_cut_list = this.smplForm.smpl_cut_list.concat(cloneData);
      // this.cutSelect = [];
      // this.$refs.subTable.$refs.multiTable.clearSelection();
      //编号重新计算
      for (let it = 0; it < this.smplForm.smpl_cut_list.length; it++) {
        this.smplForm.smpl_cut_list[it].mtrl_no = it + 1;
      }
    },
    confirmSelectData(data) {
      if (this.combinationChildSelectIndex === '') return this.$message.warning('请选择裁片名称的输入框后再选择！');
      let name = '';
      let id = '';
      for (let i = 0; i < data.length; i++) {
        let split = data[i].split('#');
        id = id + split[0];
        name = name + split[1];
        if (i < data.length - 1) {
          name = name + ',';
          id = id + ',';
        }
      }
      this.$set(this.smplForm.smpl_combination_List[this.combinationChildSelectIndex], 'mtrlNameSum', name);
      this.$set(this.smplForm.smpl_combination_List[this.combinationChildSelectIndex], 'mtrlIdSum', id);
      this.combinationChildSelectIndex = '';
      this.$refs.smplEditCutCheckBoxChild.hiddenSelectedData();
    },
    //组合新增
    addCombination() {
      this.$message.warning('添加操作后请记得保存！');
      let temp = {
        mtrlNameSum: null,
        craftActionIds: null,
        craftRemark: null,
        createNewMtrl: null,
        mtrlType: 1
      };
      this.smplForm.smpl_combination_List.push(temp);
      //编号重新计算
      for (let it = 0; it < this.smplForm.smpl_combination_List.length; it++) {
        this.smplForm.smpl_combination_List[it].mtrlNo = it + 1;
      }
    },
    //组合删除
    delCombination() {
      if (this.combinationSelect.length === 0) return this.$message.warning('至少选择一条数据');
      let comList = [];
      //循环加入要删除的数组
      for (let i = this.combinationSelect[0].index; i < this.smplForm.smpl_combination_List.length; i++) {
        if (this.smplForm.smpl_combination_List[i].id !== null) {
          comList.push(this.smplForm.smpl_combination_List[i]);
        }
      }
      //从页面上剔除要删除的数据
      for (let i = this.combinationSelect[0].index; i < this.smplForm.smpl_combination_List.length; i++) {
        this.smplForm.smpl_combination_List.splice(i);
      }
      //格式化数据
      if (comList.length > 0) {
        for (let i = 0; i < comList.length; i++) {
          if (comList[i].craftActionIds !== '') {
            comList[i].craftActionIds = comList[i].craftActionIds.join(',');
          }
        }
      }
      try {
        post(smplAPI.deleteCreateSample, {
          smpl_combination_List: comList
        })
          .then(res => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '删除成功'
              });
            }
            //从新获取数据
            this.getsmplInfo(0);
          })
          .catch(err => {
            this.$message.error(err);
          });
      } catch (e) {
        console.error(e);
      }
    },
    //组合选中
    handleSelectionCombination(val) {
      this.combinationSelect = [];
      this.combinationSelect = val;
    },
    //操作裁片时提示保存
    mesSave() {
      this.$refs.smplCut.submit1();
      this.$refs.smplCom.submitCom();
      // this.$message.warning('操作裁片信息和组合信息时请确保其他信息已保存！');
    },
    // 裁片新增
    addCut() {
      let item = {
        mtrl_no:
          this.smplForm.smpl_cut_list.length === 0 ? 1 : Math.max(...this.smplForm.smpl_cut_list.map(({ mtrl_no }) => Number(mtrl_no))) + 1,
        mtrl_name: null,
        smpl_parent_ids: null,
        smpl_mtrl_id: this.cutSmplMtrlId,
        smpl_parent_value: null,
        mtrl_number: '1',
        cutOption: JSON.parse(JSON.stringify(this.cutOption))
      };
      this.smplForm.smpl_cut_list.push(item);
      this.cutSmplMtrlId--;
      //编号重新计算
      for (let it = 0; it < this.smplForm.smpl_cut_list.length; it++) {
        this.smplForm.smpl_cut_list[it].mtrl_no = it + 1;
      }
    },
    // 裁片回显使用物料
    cutChangeIds(val) {
      this.smplForm.smpl_cut_list[val.$index].smpl_parent_ids = val.row.smpl_parent_value.toString();
    },
    // 裁片删除
    delCut() {
      if (this.cutSelect.length > 0) {
        this.bomFlag = true;
        this.plmtrlFlag = true;
        this.cutFlag = true;
        this.semiprodFlag = true;
        this.showSemiprod = false;
        let temp = [];
        for (let i = 0; i < this.cutSelect.length; i++) {
          let ind = null;
          ind = this.smplForm.smpl_cut_list.indexOf(this.cutSelect[i]);
          temp.push(ind);
          /*          for (let j = 0; j < this.smplForm.smpl_semiprod_list.length; j++) {
            for (let a = 0; a < this.smplForm.smpl_semiprod_list[j].smpl_parent_list.length; a++) {
              if (Number(this.cutSelect[i].smpl_mtrl_id) === this.smplForm.smpl_semiprod_list[j].smpl_parent_list[a]) {
                this.smplForm.smpl_semiprod_list[j].smpl_parent_list.splice(a, 1);
                break;
              }
            }
            for (let a = 0; a < this.smplForm.smpl_semiprod_list[j].semiprodOption.length; a++) {
              if (Number(this.cutSelect[i].smpl_mtrl_id) === this.smplForm.smpl_semiprod_list[j].semiprodOption[a].smpl_mtrl_id) {
                this.smplForm.smpl_semiprod_list[j].semiprodOption.splice(a, 1);
                break;
              }
            }
          }
          for (let j in this.semiprodOption) {
            if (Number(this.cutSelect[i].smpl_mtrl_id) === this.semiprodOption[j].smpl_mtrl_id) {
              this.semiprodOption.splice(j, 1);
              break;
            }
          }*/
        }
        /*        for (let i = 0; i < this.smplForm.smpl_semiprod_list.length; i++) {
          // this.smplForm.smpl_semiprod_list[i].semiprodOption = JSON.parse(JSON.stringify(this.semiprodOption))
          if (this.smplForm.smpl_semiprod_list[i].smpl_parent_list.length === 0) {
            this.smplForm.smpl_semiprod_list[i].smpl_parent_ids = '';
          } else if (this.smplForm.smpl_semiprod_list[i].smpl_parent_list.length === 1) {
            this.smplForm.smpl_semiprod_list[i].smpl_parent_ids = this.smplForm.smpl_semiprod_list[i].smpl_parent_list[0].toString();
          } else {
            this.smplForm.smpl_semiprod_list[i].smpl_parent_ids = this.smplForm.smpl_semiprod_list[i].smpl_parent_list.join(',');
          }
        }*/
        temp = temp.sort((a, b) => {
          return b - a;
        });
        for (let j of temp) {
          this.smplForm.smpl_cut_list.splice(j, 1);
        }
        //自动排序
        for (let it = 0; it < this.smplForm.smpl_cut_list.length; it++) {
          this.smplForm.smpl_cut_list[it].mtrl_no = it + 1;
        }
        this.cutSelect.forEach(item => {
          this.$refs.semiProd.smpl_semiprod_list.forEach(subItem => {
            subItem.smpl_parent_list = subItem.smpl_parent_list.filter(x => x !== item.smpl_mtrl_id);
            subItem.smpl_parent_ids = subItem.smpl_parent_list.join(',');
          });
        });
        setTimeout(() => {
          this.bomFlag = false;
          this.plmtrlFlag = false;
          this.cutFlag = false;
          this.semiprodFlag = false;
          this.showSemiprod = true;
        }, 500);
      } else {
        this.$message({
          message: '至少选择一条信息',
          type: 'warning'
        });
      }
    },
    // 加工面料导入打开物料选择
    importPlmtrl() {
      this.dialogVisible1 = true;
    },
    //新增数据填写编号时修改裁片的值
    plmtrlChangeCut(scope) {
      if (scope.row.mtrl_no.indexOf(' ') === -1) {
        this.showPlmtrl = false;
        let plmtrlTemp = -1;
        let cutTemp = -1;
        let temp = {};
        temp.smpl_mtrl_id = scope.row.smpl_mtrl_id;
        temp.mtrl_no = scope.row.mtrl_no;
        temp.mtrl_prod_price = scope.row.mtrl_prod_price;
        for (let i = 0; i < this.cutOption.length; i++) {
          if (this.cutOption[i].smpl_mtrl_id === scope.row.smpl_mtrl_id) {
            cutTemp = i;
          }
        }
        for (let i = 0; i < this.plmtrlOption.length; i++) {
          if (this.plmtrlOption[i].smpl_mtrl_id === scope.row.smpl_mtrl_id) {
            plmtrlTemp = i;
          }
        }
        if (cutTemp !== -1) {
          this.cutOption[cutTemp].mtrl_no = scope.row.mtrl_no;
        } else {
          this.cutOption.push(temp);
        }
        if (plmtrlTemp !== -1) {
          this.plmtrlOption[plmtrlTemp].mtrl_no = scope.row.mtrl_no;
          this.plmtrlOption[plmtrlTemp].mtrl_name = scope.row.mtrl_name;
        } else {
          this.plmtrlOption.push(temp);
        }
        for (let i = 0; i < this.smplForm.smpl_cut_list.length; i++) {
          this.smplForm.smpl_cut_list[i].cutOption = JSON.parse(JSON.stringify(this.cutOption));
        }
        for (let i = 0; i < this.smplForm.smpl_plmtrl_list.length; i++) {
          for (let j = 0; j < this.smplForm.smpl_plmtrl_list[i].plmtrlOption.length; j++) {
            if (this.smplForm.smpl_plmtrl_list[i].plmtrlOption[j].smpl_mtrl_id === scope.row.smpl_mtrl_id) {
              this.smplForm.smpl_plmtrl_list[i].plmtrlOption[j].mtrl_no = scope.row.mtrl_no;
              this.smplForm.smpl_plmtrl_list[i].plmtrlOption[j].mtrl_name = scope.row.mtrl_name;
            }
          }
          for (let j = 0; j < this.smplForm.smpl_plmtrl_list[i].smpl_parent_listO.length; j++) {
            if (this.smplForm.smpl_plmtrl_list[i].smpl_parent_listO[j].smpl_mtrl_id === scope.row.smpl_mtrl_id) {
              this.smplForm.smpl_plmtrl_list[i].smpl_parent_listO[j].mtrl_no = scope.row.mtrl_no;
            }
          }
        }
        this.$nextTick(() => {
          this.showPlmtrl = true;
        });
      }
    },
    // 判断加工面料的勾选框
    compListChange(val, num) {
      if (this.smplForm.smpl_plmtrl_list[val.$index].mtrl_no) {
        this.showPlmtrl = false;
        if (num === 0) {
          if (this.smplForm.smpl_plmtrl_list[val.$index].mtrl_no.length < 29) {
            this.smplForm.smpl_plmtrl_list[val.$index].mtrl_no = this.smplForm.smpl_plmtrl_list[val.$index].mtrl_no + '-F';
            if (this.smplForm.smpl_plmtrl_list[val.$index].mtrl_name) {
              this.smplForm.smpl_plmtrl_list[val.$index].mtrl_name = this.smplForm.smpl_plmtrl_list[val.$index].mtrl_name + '复合';
            } else {
              this.smplForm.smpl_plmtrl_list[val.$index].mtrl_name = '复合';
            }
          }
        } else if (num === 1) {
          if (this.smplForm.smpl_plmtrl_list[val.$index].mtrl_no.length < 29) {
            this.smplForm.smpl_plmtrl_list[val.$index].mtrl_no = this.smplForm.smpl_plmtrl_list[val.$index].mtrl_no + '-Y';
          }
          if (this.smplForm.smpl_plmtrl_list[val.$index].mtrl_name) {
            this.smplForm.smpl_plmtrl_list[val.$index].mtrl_name = this.smplForm.smpl_plmtrl_list[val.$index].mtrl_name + '印花';
          } else {
            this.smplForm.smpl_plmtrl_list[val.$index].mtrl_name = '印花';
          }
        } else if (num === 2) {
          if (this.smplForm.smpl_plmtrl_list[val.$index].mtrl_no.length < 29) {
            this.smplForm.smpl_plmtrl_list[val.$index].mtrl_no = this.smplForm.smpl_plmtrl_list[val.$index].mtrl_no + '-D';
          }
          if (this.smplForm.smpl_plmtrl_list[val.$index].mtrl_name) {
            this.smplForm.smpl_plmtrl_list[val.$index].mtrl_name = this.smplForm.smpl_plmtrl_list[val.$index].mtrl_name + '电绣';
          } else {
            this.smplForm.smpl_plmtrl_list[val.$index].mtrl_name = '电绣';
          }
        }
        let plmtrlTemp = -1;
        let cutTemp = -1;
        let temp = {};
        temp.smpl_mtrl_id = val.row.smpl_mtrl_id;
        temp.mtrl_no = this.smplForm.smpl_plmtrl_list[val.$index].mtrl_no;
        temp.mtrl_name = this.smplForm.smpl_plmtrl_list[val.$index].mtrl_name;
        temp.mtrl_prod_price = this.smplForm.smpl_plmtrl_list[val.$index].mtrl_prod_price;
        for (let i = 0; i < this.cutOption.length; i++) {
          if (this.cutOption[i].smpl_mtrl_id === val.row.smpl_mtrl_id) {
            cutTemp = i;
          }
        }
        for (let i = 0; i < this.plmtrlOption.length; i++) {
          if (this.plmtrlOption[i].smpl_mtrl_id === val.row.smpl_mtrl_id) {
            plmtrlTemp = i;
          }
        }
        if (cutTemp !== -1) {
          this.cutOption[cutTemp].mtrl_no = this.smplForm.smpl_plmtrl_list[val.$index].mtrl_no;
        } else {
          this.cutOption.push(temp);
        }
        if (plmtrlTemp !== -1) {
          this.plmtrlOption[plmtrlTemp].mtrl_no = this.smplForm.smpl_plmtrl_list[val.$index].mtrl_no;
          this.plmtrlOption[plmtrlTemp].mtrl_name = this.smplForm.smpl_plmtrl_list[val.$index].mtrl_name;
        } else {
          this.plmtrlOption.push(temp);
        }
        for (let i = 0; i < this.smplForm.smpl_cut_list.length; i++) {
          this.smplForm.smpl_cut_list[i].cutOption = JSON.parse(JSON.stringify(this.cutOption));
        }
        for (let i = 0; i < this.smplForm.smpl_plmtrl_list.length; i++) {
          for (let j = 0; j < this.smplForm.smpl_plmtrl_list[i].plmtrlOption.length; j++) {
            if (this.smplForm.smpl_plmtrl_list[i].plmtrlOption[j].smpl_mtrl_id === val.row.smpl_mtrl_id) {
              this.smplForm.smpl_plmtrl_list[i].plmtrlOption[j].mtrl_no = this.smplForm.smpl_plmtrl_list[val.$index].mtrl_no;
              this.smplForm.smpl_plmtrl_list[i].plmtrlOption[j].mtrl_name = this.smplForm.smpl_plmtrl_list[val.$index].mtrl_name;
            }
          }
        }
        if (num === 0) {
          this.smplForm.smpl_plmtrl_list[val.$index].compFlag = false;
        } else if (num === 1) {
          this.smplForm.smpl_plmtrl_list[val.$index].pritFlag = false;
        }
        this.$nextTick(() => {
          this.showPlmtrl = true;
        });
      } else {
        setTimeout(() => {
          if (this.plmtrlShow) {
            this.plmtrlShow = false;
            return;
          }
          this.$message.warning('请填写物料编号');
        }, 100);
      }
    },
    // plmtrlShowChange变化
    plmtrlShowChange() {
      this.plmtrlShow = true;
    },
    // 加工面料小窗口确定
    childPlmtrl(val) {
      let plmtrlImportVal = val;
      plmtrlImportVal = plmtrlImportVal.filter(item => this.smplForm.smpl_plmtrl_list.every(item1 => item.mtrl_id !== item1.mtrl_id));
      for (let i of plmtrlImportVal) {
        i.smpl_mtrl_id = this.plmtrlSmplMtrlId;
        i.plmtrlOption = JSON.parse(JSON.stringify(this.plmtrlOption));
        this.plmtrlSmplMtrlId--;
      }
      for (let i = 0; i < plmtrlImportVal.length; i++) {
        let item = {};
        item.smpl_mtrl_id = plmtrlImportVal[i].smpl_mtrl_id;
        item.mtrl_no = plmtrlImportVal[i].mtrl_no;
        item.mtrl_name = plmtrlImportVal[i].mtrl_name;
        item.mtrl_prod_price = plmtrlImportVal[i].mtrl_prod_price;
        this.cutOption.push(item);
        this.plmtrlOption.push(item);
      }
      for (let i = 0; i < this.smplForm.smpl_cut_list.length; i++) {
        this.smplForm.smpl_cut_list[i].cutOption = JSON.parse(JSON.stringify(this.cutOption));
      }
      this.smplForm.smpl_plmtrl_list = this.smplForm.smpl_plmtrl_list.concat(plmtrlImportVal);
      this.$refs.SmplEditBom.clear();
      this.dialogVisible1 = false;
    },
    // 加工面料小窗口取消
    plmtrlChildChanel() {
      this.dialogVisible1 = false;
    },
    // 加工面料选择框选中
    handleSelectionChange2(val) {
      this.plmtrlSelect = [];
      this.plmtrlSelect = val;
    },
    // 加工面料删除回显物料
    deletePlmtrl(scope, val) {
      this.showPlmtrl = false;
      let temp = JSON.parse(JSON.stringify(val));
      let ind = null;
      for (let i = 0; i < this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_listO.length; i++) {
        this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_listO[i].key = i;
        if (this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_listO[i].smpl_mtrl_id === temp.smpl_mtrl_id) {
          ind = i;
        }
      }
      if (this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_list.indexOf(temp.smpl_mtrl_id) !== -1) {
        this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_list.splice(
          this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_list.indexOf(temp.smpl_mtrl_id),
          1
        );
        this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_ids =
          this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_list.join(',');
      }
      this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_listO.splice(ind, 1);
      if (this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_listO.length > 0) {
        this.smplForm.smpl_plmtrl_list[scope.$index].mtrl_no = this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_listO[0].mtrl_no;
      } else {
        this.smplForm.smpl_plmtrl_list[scope.$index].mtrl_no = '';
      }
      if (this.smplForm.smpl_plmtrl_list[scope.$index].mtrl_no.indexOf('-F') !== -1) {
        this.smplForm.smpl_plmtrl_list[scope.$index].compFlag = false;
      } else {
        this.smplForm.smpl_plmtrl_list[scope.$index].compFlag = true;
      }
      if (this.smplForm.smpl_plmtrl_list[scope.$index].mtrl_no.indexOf('-Y') !== -1) {
        this.smplForm.smpl_plmtrl_list[scope.$index].pritFlag = false;
      } else {
        this.smplForm.smpl_plmtrl_list[scope.$index].pritFlag = true;
      }
      // this.smplForm.smpl_plmtrl_list[scope.$index].compFlag = true;
      // this.smplForm.smpl_plmtrl_list[scope.$index].pritFlag = true;
      this.clacSmplPrice(scope, val);
      this.$nextTick(() => {
        this.showPlmtrl = true;
      });
    },
    // 计算加工面料没一行价格及总价
    clacSmplPrice(scope, val) {
      let priceList = 0;
      for (let i = 0; i < this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_listO.length; i++) {
        priceList = priceList + Number(this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_listO[i].mtrl_prod_price);
      }
      this.smplForm.smpl_plmtrl_list[scope.$index].mtrl_prod_price = this.helper.haveFour(priceList);
      this.priceChange2(scope.$index);
    },
    // 加工面料回显使用物料
    plmtrlChangeIds(scope, val) {
      this.showPlmtrl = false;
      for (let i = 0; i < this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_listO.length; i++) {
        this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_listO[i].key = i;
      }
      let temp = JSON.parse(JSON.stringify(val));
      temp.key = this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_listO.length;
      this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_listO.push(JSON.parse(JSON.stringify(temp)));
      this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_list.push(JSON.parse(JSON.stringify(Number(temp.smpl_mtrl_id))));
      this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_ids =
        this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_list.join(',');
      if (!this.smplForm.smpl_plmtrl_list[scope.$index].mtrl_no) {
        this.smplForm.smpl_plmtrl_list[scope.$index].mtrl_no = this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_listO[0].mtrl_no;
      } else {
        this.smplForm.smpl_plmtrl_list[scope.$index].mtrl_no +=
          '-' +
          this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_listO[
            this.smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_listO.length - 1
          ].mtrl_no;
      }
      this.smplForm.smpl_plmtrl_list[scope.$index].mtrl_name = this.smplForm.smpl_plmtrl_list[scope.$index].mtrl_name
        ? this.smplForm.smpl_plmtrl_list[scope.$index].mtrl_name
        : '';
      this.smplForm.smpl_plmtrl_list[scope.$index].mtrl_name += val.mtrl_spec ? val.mtrl_spec + val.mtrl_name : '' + val.mtrl_name;
      if (this.smplForm.smpl_plmtrl_list[scope.$index].mtrl_no.indexOf('-F') !== -1) {
        this.smplForm.smpl_plmtrl_list[scope.$index].compFlag = false;
      } else {
        this.smplForm.smpl_plmtrl_list[scope.$index].compFlag = true;
      }
      if (this.smplForm.smpl_plmtrl_list[scope.$index].mtrl_no.indexOf('-Y') !== -1) {
        this.smplForm.smpl_plmtrl_list[scope.$index].pritFlag = false;
      } else {
        this.smplForm.smpl_plmtrl_list[scope.$index].pritFlag = true;
      }
      this.clacSmplPrice(scope, val);
      this.$nextTick(() => {
        this.showPlmtrl = true;
      });
    },
    // 加工面料新增
    addPlmtrl() {
      if (
        (this.smplForm.smpl_plmtrl_list.length > 0 && this.smplForm.smpl_plmtrl_list[this.smplForm.smpl_plmtrl_list.length - 1].mtrl_no) ||
        this.smplForm.smpl_plmtrl_list.length === 0
      ) {
        this.plmtrlFlag = true;
        setTimeout(() => {
          let item = {
            mtrl_no: null,
            mtrl_name: null,
            mtrl_unit: '米',
            mtrl_prod_price: '0.0000',
            smpl_parent_ids: null,
            smpl_mtrl_id: this.plmtrlSmplMtrlId,
            smpl_parent_list: [],
            smpl_parent_listO: [],
            smpl_prit_addcost: '0.0000',
            smpl_comp_addcost: '0.0000',
            smpl_mtrl_type: 0,
            compFlag: true,
            pritFlag: true,
            // smpl_mtrl_loss:this.settNum,
            smpl_mtrl_addcost: '0.0000',
            totalChange: '0.0000',
            smpl_mtrl_consume: '1.0000',
            plmtrlOption: JSON.parse(JSON.stringify(this.plmtrlOption))
          };
          this.smplForm.smpl_plmtrl_list.push(item);
          this.plmtrlSmplMtrlId--;
          this.plmtrlFlag = false;
        }, 500);
      } else {
        this.$message.warning('请把物料编号补充完整');
      }
    },
    // 加工面料金额计算
    priceChange2(val) {
      let ind = Number(val);
      this.smplForm.smpl_plmtrl_list[ind].totalChange = this.helper.haveFour(
        ((Number(this.smplForm.smpl_plmtrl_list[ind].mtrl_prod_price) * 100000 +
          Number(this.smplForm.smpl_plmtrl_list[ind].smpl_comp_addcost) * 100000 +
          Number(this.smplForm.smpl_plmtrl_list[ind].smpl_prit_addcost) * 100000) *
          Number(this.smplForm.smpl_plmtrl_list[ind].smpl_mtrl_consume)) /
          100000
      );
      // this.smplForm.smpl_plmtrl_list[ind].totalChange = this.helper.haveFour(Number(this.smplForm.smpl_plmtrl_list[ind].smpl_mtrl_loss)*Number(this.smplForm.smpl_plmtrl_list[ind].mtrl_prod_price)*Number(this.smplForm.smpl_plmtrl_list[ind].smpl_mtrl_consume)+Number(this.smplForm.smpl_plmtrl_list[ind].smpl_mtrl_addcost))
    },
    // 加工面料删除
    delPlmtrl() {
      if (this.plmtrlSelect.length > 0) {
        this.bomFlag = true;
        this.plmtrlFlag = true;
        this.cutFlag = true;
        this.semiprodFlag = true;
        let temp = [];
        for (let i = 0; i < this.plmtrlSelect.length; i++) {
          let ind = null;
          ind = this.smplForm.smpl_plmtrl_list.indexOf(this.plmtrlSelect[i]);
          temp.push(ind);
          for (let j = 0; j < this.smplForm.smpl_plmtrl_list.length; j++) {
            for (let a = 0; a < this.smplForm.smpl_plmtrl_list[j].smpl_parent_list.length; a++) {
              if (Number(this.plmtrlSelect[i].smpl_mtrl_id) === this.smplForm.smpl_plmtrl_list[j].smpl_parent_list[a]) {
                this.smplForm.smpl_plmtrl_list[j].smpl_parent_list.splice(a, 1);
                break;
              }
            }
            for (let a = 0; a < this.smplForm.smpl_plmtrl_list[j].smpl_parent_listO.length; a++) {
              if (Number(this.plmtrlSelect[i].smpl_mtrl_id) === this.smplForm.smpl_plmtrl_list[j].smpl_parent_listO[a].smpl_mtrl_id) {
                this.smplForm.smpl_plmtrl_list[j].smpl_parent_listO.splice(a, 1);
                break;
              }
            }
            for (let a = 0; a < this.smplForm.smpl_plmtrl_list[j].plmtrlOption.length; a++) {
              if (Number(this.plmtrlSelect[i].smpl_mtrl_id) === this.smplForm.smpl_plmtrl_list[j].plmtrlOption[a].smpl_mtrl_id) {
                this.smplForm.smpl_plmtrl_list[j].plmtrlOption.splice(a, 1);
                break;
              }
            }
            for (let a = 0; a < this.smplForm.smpl_plmtrl_list[j].smpl_parent_listO.length; a++) {
              this.smplForm.smpl_plmtrl_list[j].smpl_parent_listO.key = a;
            }
          }
          for (let j = 0; j < this.smplForm.smpl_cut_list.length; j++) {
            if (Number(this.plmtrlSelect[i].smpl_mtrl_id) === this.smplForm.smpl_cut_list[j].smpl_parent_value) {
              this.smplForm.smpl_cut_list[j].smpl_parent_value = null;
              this.smplForm.smpl_cut_list[j].smpl_parent_ids = '';
            }
          }
          for (let j in this.plmtrlOption) {
            if (Number(this.plmtrlSelect[i].smpl_mtrl_id) === this.plmtrlOption[j].smpl_mtrl_id) {
              this.plmtrlOption.splice(j, 1);
              break;
            }
          }
          for (let j in this.cutOption) {
            if (Number(this.plmtrlSelect[i].smpl_mtrl_id) === this.cutOption[j].smpl_mtrl_id) {
              this.cutOption.splice(j, 1);
              break;
            }
          }
        }
        // for(let i=0;i<this.smplForm.smpl_plmtrl_list.length;i++){
        //   this.smplForm.smpl_plmtrl_list[i].plmtrlOption = JSON.parse(JSON.stringify(this.plmtrlOption))
        // }
        for (let i = 0; i < this.smplForm.smpl_plmtrl_list.length; i++) {
          // this.smplForm.smpl_semiprod_list[i].semiprodOption = JSON.parse(JSON.stringify(this.semiprodOption))
          if (this.smplForm.smpl_plmtrl_list[i].smpl_parent_list.length === 0) {
            this.smplForm.smpl_plmtrl_list[i].smpl_parent_ids = '';
          } else if (this.smplForm.smpl_plmtrl_list[i].smpl_parent_list.length === 1) {
            this.smplForm.smpl_plmtrl_list[i].smpl_parent_ids = this.smplForm.smpl_plmtrl_list[i].smpl_parent_list[0].toString();
          } else {
            this.smplForm.smpl_plmtrl_list[i].smpl_parent_ids = this.smplForm.smpl_plmtrl_list[i].smpl_parent_list.join(',');
          }
        }
        for (let i = 0; i < this.smplForm.smpl_cut_list.length; i++) {
          this.smplForm.smpl_cut_list[i].cutOption = JSON.parse(JSON.stringify(this.cutOption));
        }
        temp = temp.sort((a, b) => {
          return b - a;
        });
        for (let j of temp) {
          this.smplForm.smpl_plmtrl_list.splice(j, 1);
        }
        setTimeout(() => {
          this.bomFlag = false;
          this.plmtrlFlag = false;
          this.cutFlag = false;
          this.semiprodFlag = false;
        }, 500);
      } else {
        this.$message({
          message: '至少选择一条信息',
          type: 'warning'
        });
      }
    },
    // bom清单小窗口取消
    bomChildChanel() {
      this.dialogVisible = false;
    },
    bomChildChanelCopy() {
      this.dialogVisibleCopy = false;
    },
    falseBom() {
      this.bomChildChanel();
    },
    // bom清单小窗口确定
    childBom(val, val1) {
      // 此处解决下面不能输入的bug 深拷贝 val  👇
      let temp = JSON.parse(JSON.stringify(val));
      if (!(val instanceof Array)) temp = [temp];
      let bomImportVal = temp;
      bomImportVal = bomImportVal.filter(item => this.smplForm.smpl_mtrl_list.every(item1 => item.mtrl_id !== item1.mtrl_id));
      // 导致不能输入bug
      for (let i of bomImportVal) {
        i.smpl_mtrl_id = this.bomSmplMtrlId;
        i.smplMtrlLenWidList = [];
        // 导致不能输入bug ↓↓↓
        i.smpl_mtrl_consume = '1.0000';
        /*				i.smpl_mtrl_len_wid = '';
				i.smpl_mtrl_consume = 0;
				i.totalChange='0.0000';*/
        i.stock_status = val1;
        this.bomSmplMtrlId--;
      }
      for (let i = 0; i < bomImportVal.length; i++) {
        let item = {};
        item.smpl_mtrl_id = bomImportVal[i].smpl_mtrl_id;
        item.mtrl_no = bomImportVal[i].mtrl_no;
        item.mtrl_name = bomImportVal[i].mtrl_name;
        item.mtrl_prod_price = bomImportVal[i].mtrl_prod_price;
        if (bomImportVal[i].mtrl_type === 0) {
          this.plmtrlOption.push(item);
          this.cutOption.push(item);
          for (let i = 0; i < this.smplForm.smpl_plmtrl_list.length; i++) {
            this.smplForm.smpl_plmtrl_list[i].plmtrlOption.push(item);
          }
        } else if (bomImportVal[i].mtrl_type === 1) {
          this.semiprodOption.push(item);
          for (let i = 0; i < this.smplForm.smpl_semiprod_list.length; i++) {
            this.smplForm.smpl_semiprod_list[i].semiprodOption.push(item);
          }
        }
      }
      for (let i = 0; i < this.smplForm.smpl_cut_list.length; i++) {
        this.smplForm.smpl_cut_list[i].cutOption = JSON.parse(JSON.stringify(this.cutOption));
      }
      this.smplForm.smpl_mtrl_list = this.smplForm.smpl_mtrl_list.concat(bomImportVal);
      this.$refs.SmplEditBom.clear();
      this.dialogVisible = false;
      this.dialogVisibleCopy = false;
    },
    //bom清单导入
    addRow() {
      this.dialogVisible = true;
    },
    // bom辅料导入
    addRowCopy() {
      this.dialogVisibleCopy = true;
    },
    //bom清单多选删除
    delBom() {
      // let f = this.selectionsList.map((item) => {
      //   return item.smpl_mtrl_status == 1;
      // });

      // if (f.indexOf(true) != -1) {
      //   return this.$message.warning("已出库的不可以删除");
      // }
      if (this.selectionsList.length > 0) {
        this.bomFlag = true;
        this.plmtrlFlag = true;
        this.cutFlag = true;
        this.semiprodFlag = true;
        let temp = [];
        for (let i = 0; i < this.selectionsList.length; i++) {
          let ind = null;
          ind = this.smplForm.smpl_mtrl_list.indexOf(this.selectionsList[i]);
          temp.push(ind);
          if (this.selectionsList[i].mtrl_type === 0) {
            for (let j = 0; j < this.smplForm.smpl_plmtrl_list.length; j++) {
              for (let a = 0; a < this.smplForm.smpl_plmtrl_list[j].smpl_parent_list.length; a++) {
                if (Number(this.selectionsList[i].smpl_mtrl_id) === this.smplForm.smpl_plmtrl_list[j].smpl_parent_list[a]) {
                  this.smplForm.smpl_plmtrl_list[j].smpl_parent_list.splice(a, 1);
                  break;
                }
              }
              for (let a = 0; a < this.smplForm.smpl_plmtrl_list[j].smpl_parent_listO.length; a++) {
                if (Number(this.selectionsList[i].smpl_mtrl_id) === this.smplForm.smpl_plmtrl_list[j].smpl_parent_listO[a].smpl_mtrl_id) {
                  this.smplForm.smpl_plmtrl_list[j].smpl_parent_listO.splice(a, 1);
                  break;
                }
              }
              for (let a = 0; a < this.smplForm.smpl_plmtrl_list[j].plmtrlOption.length; a++) {
                if (Number(this.selectionsList[i].smpl_mtrl_id) === this.smplForm.smpl_plmtrl_list[j].plmtrlOption[a].smpl_mtrl_id) {
                  this.smplForm.smpl_plmtrl_list[j].plmtrlOption.splice(a, 1);
                  break;
                }
              }
              for (let a = 0; a < this.smplForm.smpl_plmtrl_list[j].smpl_parent_listO.length; a++) {
                this.smplForm.smpl_plmtrl_list[j].smpl_parent_listO.key = a;
              }
            }
            for (let j = 0; j < this.smplForm.smpl_cut_list.length; j++) {
              if (Number(this.selectionsList[i].smpl_mtrl_id) === this.smplForm.smpl_cut_list[j].smpl_parent_value) {
                this.smplForm.smpl_cut_list[j].smpl_parent_value = null;
                this.smplForm.smpl_cut_list[j].smpl_parent_ids = '';
              }
            }
            for (let j in this.plmtrlOption) {
              if (Number(this.selectionsList[i].smpl_mtrl_id) === this.plmtrlOption[j].smpl_mtrl_id) {
                this.plmtrlOption.splice(j, 1);
                break;
              }
            }
            for (let j in this.cutOption) {
              if (Number(this.selectionsList[i].smpl_mtrl_id) === this.cutOption[j].smpl_mtrl_id) {
                this.cutOption.splice(j, 1);
                break;
              }
            }
          } else {
            for (let j = 0; j < this.smplForm.smpl_semiprod_list.length; j++) {
              for (let a = 0; a < this.smplForm.smpl_semiprod_list[j].smpl_parent_list.length; a++) {
                if (Number(this.selectionsList[i].smpl_mtrl_id) === this.smplForm.smpl_semiprod_list[j].smpl_parent_list[a]) {
                  this.smplForm.smpl_semiprod_list[j].smpl_parent_list.splice(a, 1);
                  break;
                }
              }
              for (let a = 0; a < this.smplForm.smpl_semiprod_list[j].semiprodOption.length; a++) {
                if (Number(this.selectionsList[i].smpl_mtrl_id) === this.smplForm.smpl_semiprod_list[j].semiprodOption[a].smpl_mtrl_id) {
                  this.smplForm.smpl_semiprod_list[j].semiprodOption.splice(a, 1);
                  break;
                }
              }
            }
            for (let j in this.semiprodOption) {
              if (Number(this.selectionsList[i].smpl_mtrl_id) === this.semiprodOption[j].smpl_mtrl_id) {
                this.semiprodOption.splice(j, 1);
                break;
              }
            }
          }
        }
        for (let i = 0; i < this.smplForm.smpl_plmtrl_list.length; i++) {
          // this.smplForm.smpl_semiprod_list[i].semiprodOption = JSON.parse(JSON.stringify(this.semiprodOption))
          if (this.smplForm.smpl_plmtrl_list[i].smpl_parent_list.length === 0) {
            this.smplForm.smpl_plmtrl_list[i].smpl_parent_ids = '';
          } else if (this.smplForm.smpl_plmtrl_list[i].smpl_parent_list.length === 1) {
            this.smplForm.smpl_plmtrl_list[i].smpl_parent_ids = this.smplForm.smpl_plmtrl_list[i].smpl_parent_list[0].toString();
          } else {
            this.smplForm.smpl_plmtrl_list[i].smpl_parent_ids = this.smplForm.smpl_plmtrl_list[i].smpl_parent_list.join(',');
          }
        }
        for (let i = 0; i < this.smplForm.smpl_semiprod_list.length; i++) {
          // this.smplForm.smpl_semiprod_list[i].semiprodOption = JSON.parse(JSON.stringify(this.semiprodOption))
          if (this.smplForm.smpl_semiprod_list[i].smpl_parent_list.length === 0) {
            this.smplForm.smpl_semiprod_list[i].smpl_parent_ids = '';
          } else if (this.smplForm.smpl_semiprod_list[i].smpl_parent_list.length === 1) {
            this.smplForm.smpl_semiprod_list[i].smpl_parent_ids = this.smplForm.smpl_semiprod_list[i].smpl_parent_list[0].toString();
          } else {
            this.smplForm.smpl_semiprod_list[i].smpl_parent_ids = this.smplForm.smpl_semiprod_list[i].smpl_parent_list.join(',');
          }
        }
        // for(let i=0;i<this.smplForm.smpl_plmtrl_list.length;i++){
        //   this.smplForm.smpl_plmtrl_list[i].plmtrlOption = JSON.parse(JSON.stringify(this.plmtrlOption))
        // }
        for (let i = 0; i < this.smplForm.smpl_cut_list.length; i++) {
          this.smplForm.smpl_cut_list[i].cutOption = JSON.parse(JSON.stringify(this.cutOption));
        }
        temp = temp.sort((a, b) => {
          return b - a;
        });
        for (let j of temp) {
          this.smplForm.smpl_mtrl_list.splice(j, 1);
        }
        setTimeout(() => {
          this.bomFlag = false;
          this.plmtrlFlag = false;
          this.cutFlag = false;
          this.semiprodFlag = false;
        }, 500);
      } else {
        this.$message({
          message: '至少选择一条信息',
          type: 'warning'
        });
      }
    },
    exportExcel() {
      this.$confirm('确定下载EXCEL?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.$route.query.form_id;
          get(smplAPI.excelSamplBom, { smpl_id: str, type: 1 })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItemExcel(res.data.data.url, res.data.data.fileName);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    //bom清单选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
    },
    // Bom清单计算数据
    priceChange1(val) {
      let ind = Number(val);
      this.smplForm.smpl_mtrl_list[ind].totalChange = this.helper.haveFour(
        Number(this.smplForm.smpl_mtrl_list[ind].mtrl_prod_price) * Number(this.smplForm.smpl_mtrl_list[ind].smpl_mtrl_consume)
      );
      // this.smplForm.smpl_mtrl_list[ind].totalChange = this.helper.haveFour(Number(this.smplForm.smpl_mtrl_list[ind].smpl_mtrl_loss)*Number(this.smplForm.smpl_mtrl_list[ind].mtrl_prod_price)*Number(this.smplForm.smpl_mtrl_list[ind].smpl_mtrl_consume))
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取委托人
    getStffType() {
      get(userAPI.getAllUsersV1)
        .then(res => {
          if (res.data.code === 0) {
            let stffType = res.data.data;
            let map = new Map();
            for (let item of stffType) {
              map.set(item.stff_name, item);
            }
            stffType = [...map.values()];
            this.stffType = stffType;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取单位
    getMtrlType() {
      get(optnAPI.getAllContent, { perm_id: 10005 })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlTypeGroupOpt = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取参数信息
    getSettList() {
      get(settAPI.getSetts)
        .then(res => {
          if (res.data.code === 0) {
            let settList = res.data.data.list;
            let settNum = '';
            for (let i = 0; i < settList.length; i++) {
              if (settList[i].sett_key === 'mtrl_lossrate') {
                settNum = settList[i].sett_value;
                this.settNum = settNum;
              }
              if (settList[i].sett_key === 'freight_pru') {
                if (!this.smplForm.smpl_freight || Number(this.smplForm.smpl_freight) === 0) {
                  this.smplForm.smpl_freight = settList[i].sett_value;
                }
              }
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取表单信息
    async getsmplInfo(val, val2, val3) {
      if (val === 0) {
        this.smplForm.smpl_id = this.$route.query.form_id;
      } else if (val === 1) {
        this.smplForm.smpl_id = val2;
      }
      get(smplAPI.getSmplById, { smpl_id: this.smplForm.smpl_id })
        .then(async res => {
          if (res.data.code === 0) {
            //更新裁片和组合
            // if (val3 === 2) {
            //   //组合下面的表
            //   if (res.data.data.form.smpl_combination_List) {
            //     this.$set(this.smplForm, 'smpl_combination_List', cloneDeep(res.data.data.form.smpl_combination_List));
            //     this.smplForm.smpl_combination_List.forEach(item => {
            //       item.craftActionIds = item.craftActionIds?.split(',') ?? [];
            //       item.craftActionIds = Array.from(item.craftActionIds, item => Number(item));
            //       // item.mtrlNo = item.mtrlNo.substr(
            //       //   item.mtrlNo.indexOf('#') + 1,
            //       //   item.mtrlNo.length
            //       // );
            //     });
            //   }
            //   //组合上面的表
            //   if (res.data.data.form.smpl_combination_List1) {
            //     this.$set(this.smplForm, 'smpl_combination_List1', cloneDeep(res.data.data.form.smpl_combination_List1));
            //   }
            //   //裁片
            //   if (res.data.data.form.smpl_cut_list) {
            //     this.$set(this.smplForm, 'smpl_cut_list', cloneDeep(res.data.data.form.smpl_cut_list));
            //     this.smplForm.smpl_cut_list.forEach(data =>{
            //       let item = {};
            //       item.smpl_mtrl_id = data.smpl_mtrl_id;
            //       item.mtrl_no = JSON.parse(JSON.stringify(data.mtrl_no));
            //       this.semiprodOption.push(item);
            //       data.smpl_parent_value = Number(data.smpl_parent_ids);
            //       data.cutOption = JSON.parse(JSON.stringify(this.cutOption));
            //       data.mtrl_no = data.mtrl_no.substr(
            //         data.mtrl_no.indexOf('@') + 1,
            //         data.mtrl_no.length
            //       );
            //     })
            //   }
            //   return;
            // }
            if (res.data.data.form.smpl_combination_List) {
              res.data.data.form.smpl_combination_List.forEach(item => {
                item.craftActionIds = item.craftActionIds?.split(',') ?? [];
                item.craftActionIds = Array.from(item.craftActionIds, item => Number(item));
              });
            }
            this.bomFlag = true;
            this.plmtrlFlag = true;
            this.cutFlag = true;
            this.semiprodFlag = true;
            this.showPlmtrl = false;
            this.smplForm = res.data.data.form;
            this.btn = res.data.data.btn;
            /*							if(this.smplForm.status === 2){
                  this.btn.edit = false;
                }*/
            if (val === 1) {
              this.smplForm.smpl_type = 3;
            }
            this.smplForm.freight_flag = this.smplForm.freight_flag + '';
            let urlEng = JSON.parse(JSON.stringify(this.smplForm.imge_url));
            if (this.smplForm.rfid !== null) {
              this.showStatus = this.smplForm.rfid[0];
              if (this.showStatus.insp_status === 3) {
                this.coverFlag = true;
              }
            }
            // this.getMesProdBySmplNo();
            //根据卡的工序
            if (this.showStatus.open_status != 0) {
              this.activeName2 = 'open';
            } else if (this.showStatus.stpr_status != 0) {
              this.activeName2 = 'stpr';
            } else if (this.showStatus.comp_status != 0) {
              this.activeName2 = 'comp';
            } else if (this.showStatus.stam_status != 0) {
              this.activeName2 = 'stam';
            } else if (this.showStatus.lase_status != 0) {
              this.activeName2 = 'lase';
            } else if (this.showStatus.mase_status != 0) {
              this.activeName2 = 'mase';
            } else if (this.showStatus.embr_status != 0) {
              this.activeName2 = 'embr';
            } else if (this.showStatus.emcd_status != 0) {
              this.activeName2 = 'emcd';
            } else if (this.showStatus.manu_status != 0) {
              this.activeName2 = 'manu';
            } else if (this.showStatus.handCut_status != 0) {
              this.activeName2 = 'handCut';
            }

            if (this.smplForm.imge_id) {
              this.dataBody.imge_id = this.smplForm.imge_id;
            } else {
              this.dataBody.imge_id = 0;
            }
            let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
            this.smplForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
            this.smplForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
            if (this.show) {
              this.smplForm.imge_id = this.imgForm.imge_id;
              this.smplForm.imge_url = this.imgForm.imge_url;
              this.smplForm.imge_urlO = this.imgForm.imge_urlO;
              this.show = false;
            }
            this.imgForm.imge_url = JSON.parse(JSON.stringify(this.smplForm.imge_url));
            this.imgForm.imge_urlO = JSON.parse(JSON.stringify(this.smplForm.imge_urlO));
            this.imgForm.imge_id = JSON.parse(JSON.stringify(this.smplForm.imge_id));
            if (this.smplForm.smpl_deil_time) {
              this.smplForm.smpl_deil_time = Number(this.smplForm.smpl_deil_time) * 1000;
            } else {
              this.smplForm.smpl_deil_time = null;
            }
            if (this.smplForm.smpl_price !== null) {
              this.smplForm.smpl_price = this.helper.haveFour(this.smplForm.smpl_price);
            }
            if (this.smplForm.smpl_verify_price !== null) {
              this.smplForm.smpl_verify_price = this.helper.haveFour(this.smplForm.smpl_verify_price);
            }
            if (this.smplForm.smpl_freight !== null) {
              this.smplForm.smpl_freight = this.helper.haveFour(this.smplForm.smpl_freight);
            }
            if (this.smplForm.smpl_bag_l !== null) {
              this.smplForm.smpl_bag_l = this.helper.reservedDigits(this.smplForm.smpl_bag_l);
            }
            if (this.smplForm.smpl_bag_w !== null) {
              this.smplForm.smpl_bag_w = this.helper.reservedDigits(this.smplForm.smpl_bag_w);
            }
            if (this.smplForm.smpl_box_h !== null) {
              this.smplForm.smpl_box_h = this.helper.reservedDigits(this.smplForm.smpl_box_h);
            }
            if (this.smplForm.smpl_box_l !== null) {
              this.smplForm.smpl_box_l = this.helper.reservedDigits(this.smplForm.smpl_box_l);
            }
            if (this.smplForm.smpl_box_w !== null) {
              this.smplForm.smpl_box_w = this.helper.reservedDigits(this.smplForm.smpl_box_w);
            }
            if (this.smplForm.smpl_fill_weight !== null) {
              this.smplForm.smpl_fill_weight = this.helper.haveFour(this.smplForm.smpl_fill_weight);
            }
            if (this.smplForm.smpl_skin_weight !== null) {
              this.smplForm.smpl_skin_weight = this.helper.haveFour(this.smplForm.smpl_skin_weight);
            }
            if (this.smplForm.smpl_weight !== null) {
              this.smplForm.smpl_weight = this.helper.haveFour(this.smplForm.smpl_weight);
            }
            this.bomCopy = JSON.parse(JSON.stringify(this.smplForm.smpl_mtrl_list));
            this.plmtrlCopy = JSON.parse(JSON.stringify(this.smplForm.smpl_plmtrl_list));
            this.cutCopy = JSON.parse(JSON.stringify(this.smplForm.smpl_cut_list));
            this.semiprodCopy = JSON.parse(JSON.stringify(this.smplForm.smpl_semiprod_list));
            this.partCopy = JSON.parse(JSON.stringify(this.smplForm.smpl_part_list));
            this.smplForm.smpl_part_list.forEach((item, index) => {
              item.key = index;
              item.smpl_part_weight = this.helper.haveFour(item.smpl_part_weight);
              item.smpl_part_price = this.helper.haveFour(item.smpl_part_price);
            });
            for (let i = 0; i < this.smplForm.smpl_mtrl_list.length; i++) {
              let item = {};
              item.smpl_mtrl_id = this.smplForm.smpl_mtrl_list[i].smpl_mtrl_id;
              item.mtrl_no = this.smplForm.smpl_mtrl_list[i].mtrl_no;
              item.mtrl_name = this.smplForm.smpl_mtrl_list[i].mtrl_name;
              item.mtrl_prod_price = this.smplForm.smpl_mtrl_list[i].mtrl_prod_price;
              item.mtrl_spec = this.smplForm.smpl_mtrl_list[i].mtrl_spec ? this.smplForm.smpl_mtrl_list[i].mtrl_spec : '';
              item.mtrl_type = this.smplForm.smpl_mtrl_list[i].mtrl_type;
              if (this.smplForm.smpl_mtrl_list[i].mtrl_type === 0) {
                this.plmtrlOption.push(item);
                this.cutOption.push(item);
              } else if (this.smplForm.smpl_mtrl_list[i].mtrl_type === 1) {
                this.plmtrlOption.push(item);
                this.semiprodOption.push(item);
              }
              this.smplForm.smpl_mtrl_list[i].totalChange = this.helper.haveFour(
                Number(this.smplForm.smpl_mtrl_list[i].mtrl_prod_price) * Number(this.smplForm.smpl_mtrl_list[i].smpl_mtrl_consume)
              );
              if (this.smplForm.smpl_mtrl_list[i].smpl_mtrl_consume) {
                this.smplForm.smpl_mtrl_list[i].smpl_mtrl_consume = this.helper.haveFour(this.smplForm.smpl_mtrl_list[i].smpl_mtrl_consume);
              }
              // if(this.smplForm.smpl_mtrl_list[i].smpl_mtrl_loss){
              //   this.smplForm.smpl_mtrl_list[i].smpl_mtrl_loss = this.helper.reservedDigits(this.smplForm.smpl_mtrl_list[i].smpl_mtrl_loss)
              // }
              if (this.smplForm.smpl_mtrl_list[i].mtrl_prod_price !== null) {
                this.smplForm.smpl_mtrl_list[i].mtrl_prod_price = this.helper.haveFour(this.smplForm.smpl_mtrl_list[i].mtrl_prod_price);
              }
              if (this.smplForm.smpl_mtrl_list[i].mtrl_width !== null) {
                this.smplForm.smpl_mtrl_list[i].mtrl_width = this.helper.haveFour(this.smplForm.smpl_mtrl_list[i].mtrl_width);
              }
              if (this.smplForm.smpl_mtrl_list[i].mtrl_thick !== null) {
                this.smplForm.smpl_mtrl_list[i].mtrl_thick = this.helper.haveFour(this.smplForm.smpl_mtrl_list[i].mtrl_thick);
              }
              if (this.smplForm.smpl_mtrl_list[i].mtrl_weight !== null) {
                this.smplForm.smpl_mtrl_list[i].mtrl_weight = this.helper.reservedDigits(this.smplForm.smpl_mtrl_list[i].mtrl_weight);
              }
              if (this.smplForm.smpl_mtrl_list[i].smpl_mtrl_length !== null) {
                this.smplForm.smpl_mtrl_list[i].smpl_mtrl_length = this.helper.reservedDigits(
                  this.smplForm.smpl_mtrl_list[i].smpl_mtrl_length
                );
              }
              if (this.smplForm.smpl_mtrl_list[i].smpl_mtrl_width !== null) {
                this.smplForm.smpl_mtrl_list[i].smpl_mtrl_width = this.helper.reservedDigits(
                  this.smplForm.smpl_mtrl_list[i].smpl_mtrl_width
                );
              }
              this.smplForm.smpl_mtrl_list[i].smplMtrlLenWidList = [];
              if (this.smplForm.smpl_mtrl_list[i].smpl_mtrl_len_wid) {
                let smplMtrlLenWidList = this.smplForm.smpl_mtrl_list[i].smpl_mtrl_len_wid.split(',');
                for (let j = 0; j < smplMtrlLenWidList.length; j++) {
                  let temp = {};
                  temp.key = j;
                  temp.label = smplMtrlLenWidList[j];
                  if (this.smplForm.smpl_mtrl_list[i].smpl_mtrl_status === 1) {
                    temp.closableFlag = false;
                  } else {
                    temp.closableFlag = true;
                  }
                  this.smplForm.smpl_mtrl_list[i].smplMtrlLenWidList.push(temp);
                }
              }
            }
            for (let i = 0; i < this.smplForm.smpl_plmtrl_list.length; i++) {
              this.smplForm.smpl_plmtrl_list[i].plmtrlOption = JSON.parse(JSON.stringify(this.plmtrlOption));
            }
            for (let i = 0; i < this.smplForm.smpl_plmtrl_list.length; i++) {
              this.smplForm.smpl_plmtrl_list[i].smpl_parent_list = [];
              if (this.smplForm.smpl_plmtrl_list[i].smpl_parent_ids.indexOf(',') >= 0) {
                let arr = this.smplForm.smpl_plmtrl_list[i].smpl_parent_ids.split(',');
                for (let j in arr) {
                  arr[j] = Number(arr[j]);
                }
                this.smplForm.smpl_plmtrl_list[i].smpl_parent_list = arr;
              } else {
                let arr = this.smplForm.smpl_plmtrl_list[i].smpl_parent_ids.split(' ');
                for (let j in arr) {
                  arr[j] = Number(arr[j]);
                }
                this.smplForm.smpl_plmtrl_list[i].smpl_parent_list = arr;
              }
              let item = {};
              item.smpl_mtrl_id = this.smplForm.smpl_plmtrl_list[i].smpl_mtrl_id;
              item.mtrl_no = this.smplForm.smpl_plmtrl_list[i].mtrl_no;
              item.mtrl_name = this.smplForm.smpl_plmtrl_list[i].mtrl_name;
              item.mtrl_prod_price = this.smplForm.smpl_plmtrl_list[i].mtrl_prod_price;
              this.plmtrlOption.push(item);
              this.cutOption.push(item);
              if (this.smplForm.smpl_plmtrl_list[i].mtrl_prod_price !== null) {
                this.smplForm.smpl_plmtrl_list[i].mtrl_prod_price = this.helper.haveFour(this.smplForm.smpl_plmtrl_list[i].mtrl_prod_price);
              }
              if (this.smplForm.smpl_plmtrl_list[i].mtrl_no.indexOf('-F') !== -1) {
                this.smplForm.smpl_plmtrl_list[i].compFlag = false;
              } else {
                this.smplForm.smpl_plmtrl_list[i].compFlag = true;
              }
              if (this.smplForm.smpl_plmtrl_list[i].mtrl_no.indexOf('-Y') !== -1) {
                this.smplForm.smpl_plmtrl_list[i].pritFlag = false;
              } else {
                this.smplForm.smpl_plmtrl_list[i].pritFlag = true;
              }
              for (let j = i + 1; j < this.smplForm.smpl_plmtrl_list.length; j++) {
                this.smplForm.smpl_plmtrl_list[j].plmtrlOption.push(JSON.parse(JSON.stringify(item)));
              }
            }
            for (let i = 0; i < this.smplForm.smpl_plmtrl_list.length; i++) {
              this.smplForm.smpl_plmtrl_list[i].smpl_parent_listO = [];
              // this.smplForm.smpl_plmtrl_list[i].smpl_mtrl_loss = this.helper.reservedDigits(this.smplForm.smpl_plmtrl_list[i].smpl_mtrl_loss)
              this.smplForm.smpl_plmtrl_list[i].smpl_mtrl_consume = this.helper.haveFour(
                this.smplForm.smpl_plmtrl_list[i].smpl_mtrl_consume
              );

              // if(this.smplForm.smpl_plmtrl_list[i].smpl_prit_addcost){
              //   this.smplForm.smpl_plmtrl_list[i].smpl_prit_addcost = this.helper.haveFour(this.smplForm.smpl_plmtrl_list[i].smpl_prit_addcost)
              // }else{
              //   this.smplForm.smpl_plmtrl_list[i].smpl_prit_addcost = '0.0000'
              // }
              // if(this.smplForm.smpl_plmtrl_list[i].smpl_comp_addcost){
              //   this.smplForm.smpl_plmtrl_list[i].smpl_comp_addcost = this.helper.haveFour(this.smplForm.smpl_plmtrl_list[i].smpl_comp_addcost)
              // }else{
              //   this.smplForm.smpl_plmtrl_list[i].smpl_comp_addcost = '0.0000'
              // }
              this.smplForm.smpl_plmtrl_list[i].totalChange = this.helper.haveFour(
                ((Number(this.smplForm.smpl_plmtrl_list[i].mtrl_prod_price) * 100000 +
                  Number(this.smplForm.smpl_plmtrl_list[i].smpl_comp_addcost) * 100000 +
                  Number(this.smplForm.smpl_plmtrl_list[i].smpl_prit_addcost) * 100000) *
                  Number(this.smplForm.smpl_plmtrl_list[i].smpl_mtrl_consume)) /
                  100000
              );
              for (let j = 0; j < this.smplForm.smpl_plmtrl_list[i].smpl_parent_list.length; j++) {
                let temp = this.smplForm.smpl_plmtrl_list[i].plmtrlOption.filter(item => {
                  return item.smpl_mtrl_id === this.smplForm.smpl_plmtrl_list[i].smpl_parent_list[j];
                });
                if (temp[0]) {
                  temp[0].key = JSON.parse(JSON.stringify(j));
                  this.smplForm.smpl_plmtrl_list[i].smpl_parent_listO.push(JSON.parse(JSON.stringify(temp[0])));
                }
              }
            }
            for (let i = 0; i < this.smplForm.smpl_cut_list.length; i++) {
              let item = {};
              item.smpl_mtrl_id = this.smplForm.smpl_cut_list[i].smpl_mtrl_id;
              item.mtrl_no = JSON.parse(JSON.stringify(this.smplForm.smpl_cut_list[i].mtrl_no));
              this.semiprodOption.push(item);
              this.smplForm.smpl_cut_list[i].smpl_parent_value = Number(this.smplForm.smpl_cut_list[i].smpl_parent_ids);
              this.smplForm.smpl_cut_list[i].cutOption = JSON.parse(JSON.stringify(this.cutOption));
              this.smplForm.smpl_cut_list[i].mtrl_no = this.smplForm.smpl_cut_list[i].mtrl_no.substr(
                this.smplForm.smpl_cut_list[i].mtrl_no.indexOf('@') + 1,
                this.smplForm.smpl_cut_list[i].mtrl_no.length
              );
            }
            for (let i = 0; i < this.smplForm.smpl_semiprod_list.length; i++) {
              this.smplForm.smpl_semiprod_list[i].semiprodOption = JSON.parse(JSON.stringify(this.semiprodOption));
            }
            for (let i = 0; i < this.smplForm.smpl_semiprod_list.length; i++) {
              this.smplForm.smpl_semiprod_list[i].smpl_parent_list = [];
              if (this.smplForm.smpl_semiprod_list[i].smpl_parent_ids.indexOf(',') >= 0) {
                let arr = this.smplForm.smpl_semiprod_list[i].smpl_parent_ids.split(',');
                for (let j in arr) {
                  arr[j] = Number(arr[j]);
                }
                this.smplForm.smpl_semiprod_list[i].smpl_parent_list = arr;
              } else {
                let arr = this.smplForm.smpl_semiprod_list[i].smpl_parent_ids.split(' ');
                for (let j in arr) {
                  arr[j] = Number(arr[j]);
                }
                this.smplForm.smpl_semiprod_list[i].smpl_parent_list = arr;
              }
              let item = {};
              item.smpl_mtrl_id = this.smplForm.smpl_semiprod_list[i].smpl_mtrl_id;
              item.mtrl_no = this.smplForm.smpl_semiprod_list[i].mtrl_no;
              for (let j = i + 1; j < this.smplForm.smpl_semiprod_list.length; j++) {
                this.smplForm.smpl_semiprod_list[j].semiprodOption.push(JSON.parse(JSON.stringify(item)));
              }
              this.semiprodOption.push(JSON.parse(JSON.stringify(item)));
              this.smplForm.smpl_semiprod_list[i].mtrl_no = this.smplForm.smpl_semiprod_list[i].mtrl_no.substr(
                this.smplForm.smpl_semiprod_list[i].mtrl_no.indexOf('#') + 1,
                this.smplForm.smpl_semiprod_list[i].mtrl_no.length
              );
            }
            for (let i = 0; i < this.smplForm.smpl_combination_List.length; i++) {
              this.smplForm.smpl_combination_List[i].mtrlNo = this.smplForm.smpl_combination_List[i].mtrlNo.substr(
                this.smplForm.smpl_combination_List[i].mtrlNo.indexOf('#') + 1,
                this.smplForm.smpl_combination_List[i].mtrlNo.length
              );
            }
            this.$refs.semiProd.smpl_semiprod_list = cloneDeep(this.smplForm.smpl_semiprod_list);
            this.getInputUser();
            this.getSettList();
            setTimeout(() => {
              this.bomFlag = false;
              this.plmtrlFlag = false;
              this.cutFlag = false;
              this.semiprodFlag = false;
            }, 1000);
            //没有刷卡直接提交数据
            if (!res.data.data.procMap) {
              this.rfid_Info.rfid_id = res.data.data.procMap.stpr.rfid_id;
              this.rfid_Info.user_id = res.data.data.procMap.stpr.user_id;
              this.rfid_Info.stff_name = res.data.data.procMap.stpr.stff_name;
              this.rfid_Info.stff_id = res.data.data.procMap.stpr.stff_id;
            } else {
              let userInfo = this.$cookies.get('userInfo');
              if (res.data.data.form.rfid) {
                this.rfid_Info.rfid_id = res.data.data.form.rfid[0].rfid_id;
              }

              this.rfid_Info.user_id = userInfo.user_id;
              this.rfid_Info.stff_name = userInfo.stff_name;
              this.rfid_Info.stff_id = userInfo.stff_id;
            }
            if (!res.data.data.form.rfid || res.data.data.form.rfid[0].insp_status === 3) {
              this.editFlag = true;
            }
            /*							const rework = this.$route.query.rework
                if (rework === 'true') {
                  let stpr = res.data.data.procMap.stpr;
                  res.data.data.procMap = {};
                  res.data.data.procMap.stpr = stpr;
                }*/

            // open 开版
            this.rfid_perm_status = res.data.data.form.rfid_perm_status;
            if (res.data.data.procMap.open) {
              this.openProp = JSON.parse(JSON.stringify(res.data.data.procMap.open));
              this.originalOpen = JSON.parse(JSON.stringify(res.data.data.procMap.open));
            }
            // lase 激光
            if (res.data.data.procMap.lase) {
              this.laseProp = JSON.parse(JSON.stringify(res.data.data.procMap.lase));
              this.originalLase = JSON.parse(JSON.stringify(res.data.data.procMap.lase));
            }
            // handCut 手裁
            if (res.data.data.procMap.handCut) {
              this.handCutProp = JSON.parse(JSON.stringify(res.data.data.procMap.handCut));
              this.originalhandCut = JSON.parse(JSON.stringify(res.data.data.procMap.handCut));
            }
            // mase 机缝
            if (res.data.data.procMap.mase) {
              this.maseProp = JSON.parse(JSON.stringify(res.data.data.procMap.mase));
              setTimeout(() => {
                 this.$refs.maseRef.initData();
              }, 1000);
              this.$EventBus.$emit('maseEvent', this.maseProp);
            }
            // manu 手工
            if (res.data.data.procMap.manu) {
              this.manuProp = JSON.parse(JSON.stringify(res.data.data.procMap.manu));
              this.$EventBus.$emit('manuEvent', this.manuProp);
            }
            // emcd 制卡
            if (res.data.data.procMap.emcd) {
              this.emcdProp = JSON.parse(JSON.stringify(res.data.data.procMap.emcd));

              this.$EventBus.$emit('emcdEvent', this.emcdProp);
            }
            // embr 电绣
            if (res.data.data.procMap.embr) {
              this.embrProp = JSON.parse(JSON.stringify(res.data.data.procMap.embr));
              this.originalEmbr = JSON.parse(JSON.stringify(res.data.data.procMap.embr));
            }
            // comp 复合
            if (res.data.data.procMap.comp) {
              this.compProp = JSON.parse(JSON.stringify(res.data.data.procMap.comp));
              this.originalComp = JSON.parse(JSON.stringify(res.data.data.procMap.comp));
            }
            // stam 印花
            if (res.data.data.procMap.stam) {
              this.stamProp = JSON.parse(JSON.stringify(res.data.data.procMap.stam));
              this.originalStam = JSON.parse(JSON.stringify(res.data.data.procMap.stam));
            }
            // stpr 备料
            if (res.data.data.procMap.stpr) {
              this.stprProp = JSON.parse(JSON.stringify(res.data.data.procMap.stpr));
              this.originalStpr = JSON.parse(JSON.stringify(res.data.data.procMap.stpr));

              this.stprProp.rfid_stpr_phr += '';
            }
            if (!this.stprProp.rfid_stpr_phr) {
              this.stprProp.rfid_stpr_phr = res.data.data.form.rfid_stpr_num ? (res.data.data.form.rfid_stpr_num += '') : '';
            }

            if (res.data.data.procMap.insp) {
              this.inspProp = JSON.parse(JSON.stringify(res.data.data.procMap.insp));
            }
            setTimeout(() => {
              this.showPlmtrl = true;
              this.loadingFlag = false;
            }, 1000);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(e => console.log(e));
    },
    //获取录入人
    getInputUser() {
      this.stffForm.cptt_id = this.smplForm.cptt_id;
      this.stffForm.user_id = this.smplForm.user_id;
      this.stffForm.dept_id = this.smplForm.dept_id;
      this.stffForm.stff_id = this.smplForm.stff_id;
      this.stffForm.dept_name = this.smplForm.dept_name;
      this.stffForm.dept_team_id = this.smplForm.dept_team_id;
      this.stffForm.dept_team_name = this.smplForm.dept_team_name;
      this.stffForm.stff_name = this.smplForm.stff_name;
      this.stffForm.create_time = this.smplForm.create_time;
    },
    //开版
    openPhrChange(val) {
      this.openProp.rfid_open_phr = val;
    },
    // 开班
    cutNumChange(val) {
      this.openProp.rfid_cut_num = val;
    },
    // 机锋
    cutNumChange1(val) {
      this.maseProp.rfid_cut_num = val;
    },
    //复合
    compPhrChange(val) {
      this.compProp.rfid_comp_phr = val;
    },
    //印花
    stamPhrChange(val) {
      this.stamProp.rfid_stam_phr = val;
    },
    //激光
    lasePhrChange(val) {
      this.laseProp.rfid_lase_phr = val;
    },
    lasePatchChange(val) {
      this.laseProp.rfid_patch_lase = val;
    },
    //手裁
    rfid_handcut_phrChange(val) {
      this.handCutProp.rfid_handcut_phr = val;
    },
    sample_phrChange(val) {
      this.handCutProp.sample_phr = val;
    },
    sample_numChange(val) {
      this.handCutProp.sample_num = val;
    },
    //电绣
    embrPhrChange(val) {
      this.embrProp.rfid_embr_phr = val;
    },
    addnum() {
      this.num++;
    },
    del() {
      this.num--;
    },
    //备料修改信息
    stprPhrInt(val) {
      this.stprProp.rfid_stpr_phr = val;
    },
    //开版保存后更新数据
    copyOpen(prop) {
      this.originalOpen.rfid_cut_num = prop.rfid_cut_num;
      this.originalOpen.rfid_open_phr = prop.rfid_open_phr;
    },
    //备料保存后更新数据
    copyStpr(prop) {
      this.originalStpr.rfid_stpr_phr = prop.rfid_stpr_phr;
    },
    //复合保存后更新数据
    copyComp(prop) {
      this.originalComp.rfid_comp_phr = prop.rfid_comp_phr;
    },
    copyStam(prop) {
      this.originalStam.rfid_stam_phr = prop.rfid_stam_phr;
    },
    //激光保存后更新数据
    copyLase(prop) {
      this.originalLase.rfid_lase_phr = prop.rfid_lase_phr;
      this.originalLase.rfid_patch_lase = prop.rfid_patch_lase;
    },
    //手裁保存后更新数据
    copyHand(prop) {
      this.originalhandCut.rfid_handcut_phr = prop.rfid_handcut_phr;
      this.originalhandCut.sample_phr = prop.sample_phr;
      this.originalhandCut.sample_num = prop.sample_num;
    },
    //电绣保存后更新数据
    copyEmbr(prop) {
      this.originalEmbr.rfid_embr_phr = prop.rfid_embr_phr;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_eltab {
  margin-top: 10px;
}

.vd_buttom {
  border-bottom: 1px dashed #c0c4cc;
  padding-bottom: 32px;
}

.vd_but {
  margin-bottom: 10px;
}

.vd_ml10 {
  margin-left: 10px;
}

.vd_dis {
  display: inline-block;
}

.vd_mrl {
  margin-left: 5px;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
  margin-bottom: 20px;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_export1 {
  position: absolute;
  top: 0;
  left: 130px;
}

.vd_export2 {
  position: absolute;
  top: 0;
  left: 185px;
  background-color: rgb(230, 215, 7) !important;
  border: 1px solid rgb(230, 215, 7) !important;
}

.vd_export2:hover {
  background-color: rgb(232, 222, 93) !important;
  border: 1px solid rgb(232, 222, 93) !important;
}

.vd_exports {
  position: absolute;
  top: 0;
  left: 120px;
}

.vd_mrb {
  margin-bottom: 1px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_cussor {
  cursor: default;
}
</style>
